import axios from 'axios';
import {toast} from 'react-toastify';
import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {http} from '../../http';
import {updateCurrentOrder} from '../groupActions';

export const setCountryCode = (code) => {
  return {
    type: actionTypes.COUNTRY_CODE,
    payload: code,
  };
};

export const setMobileNumber = (number) => {
  return {
    type: actionTypes.MOBILE_NUMBER,
    payload: number,
  };
};

export const setTrueCallerReqID = (id) => {
  return {
    type: actionTypes.TRUECALLER_REQ_ID,
    payload: id,
  };
};

export const getOTP = (phoneNumber) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOGIN_API_LOADING, payload: true});
    axios
      .post(`${API_URL}/users/auth/sendotp`, {
        phone: phoneNumber,
      })
      .then((res) => {
        dispatch({type: actionTypes.OTP_SENT, payload: true});
        dispatch({type: actionTypes.WHATSAPP_OTP_SENT, payload: false});
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
      });
  };
};

export const getWhatsappOTP = (phoneNumber) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOGIN_API_LOADING, payload: true});
    axios
      .post(`${API_URL}/users/whatsapp/otp`, {
        phone: phoneNumber,
      })
      .then((res) => {
        dispatch({type: actionTypes.OTP_SENT, payload: true});
        dispatch({type: actionTypes.WHATSAPP_OTP_SENT, payload: true});
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
      });
  };
};

export const resendOTP = (phoneNumber) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOGIN_API_LOADING, payload: true});
    axios
      .post(`${API_URL}/users/auth/resendotp`, {
        phone: phoneNumber,
      })
      .then(() => {
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
      });
  };
};

export const loginUser = (data, url, redirect) => {
  return (dispatch, getState) => {
    dispatch({type: actionTypes.LOGIN_API_LOADING, payload: true});
    const state = getState();
    const whatsapp = state.auth && state.auth.wpOtpSent;
    let href = `${API_URL}/users/auth/login`;
    if (whatsapp) {
      href = `${API_URL}/users/whatsapp/otp/verify`;
    }
    axios
      .post(href, data)
      .then((res) => {
        localStorage.setItem('auth_token', res.data.token);
        dispatch({type: actionTypes.LOGIN_SUCCESS, payload: res.data});
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
        if (url) {
          if (res.data.user.current_order) {
            redirect(url);
          } else {
            redirect(url, true);
          }
        }
      })
      .catch(() => {
        dispatch({type: actionTypes.LOGIN_ERROR, payload: true});
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
      });
  };
};

export const loginFirebaseUser = (data, url, redirect) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOGIN_API_LOADING, payload: true});
    axios
      .post(`${API_URL}/users/fb/login`, data)
      .then((res) => {
        localStorage.setItem('auth_token', res.data.token);
        dispatch({type: actionTypes.LOGIN_SUCCESS, payload: res.data});
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
        if (url) {
          if (res.data.user.current_order) {
            redirect(url);
          } else {
            redirect(url, true);
          }
        }
      })
      .catch(() => {
        dispatch({type: actionTypes.LOGIN_ERROR, payload: true});
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
      });
  };
};

export const fetchUserDetails = () => {
  return (dispatch) => {
    http
      .get(`${API_URL}/users`)
      .then((res) => {
        dispatch({type: actionTypes.UPDATE_LOGIN_DATA, payload: res.data});
        if (res.data && res.data.current_order) {
          dispatch(updateCurrentOrder(res.data.current_order));
        }
      })
      .catch(() => {});
  };
};

export const loginViaToken = (token, cb) => {
  return (dispatch) => {
    axios
      .get(`${API_URL}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        localStorage.setItem('auth_token', token);
        dispatch({
          type: actionTypes.LOGIN_SUCCESS,
          payload: {
            phone: res.data.phone,
            token: token,
            user: res.data,
          },
        });
        if (res.data && res.data.current_order) {
          dispatch(updateCurrentOrder(res.data.current_order));
        }
        cb();
      })
      .catch(() => {
        toast.error('Authorization failed');
      });
  };
};

export const getUserCredits = () => {
  return (dispatch) => {
    http
      .get(`${API_URL}/crypto`)
      .then((res) => {
        dispatch({type: actionTypes.UPDATE_USER_CREDITS, payload: res.data});
      })
      .catch(() => {});
  };
};

export const updateUserDetails = (details, cb) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: true});
    http
      .put(`${API_URL}/users`, details)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_LOGIN_DATA,
          payload: res.data.user,
        });
        await dispatch({
          type: actionTypes.LOADING_SEND_TO_KITCHEN,
          payload: false,
        });
        if (cb) {
          await cb();
        }
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: false});
      });
  };
};

export const resetLogin = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.OTP_SENT,
      payload: false,
    });
    dispatch({
      type: actionTypes.WHATSAPP_OTP_SENT,
      payload: false,
    });
  };
};

export const resetLoginErr = () => {
  return {
    type: actionTypes.LOGIN_ERROR,
    payload: false,
  };
};

export const logoutUser = () => ({
  type: actionTypes.CLEAR_USER,
});

export const updateUserEbill = (data) => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload: data,
});

export const updateUserOrder = (orderID) => {
  return {
    type: actionTypes.UPDATE_USER_ORDER,
    payload: orderID,
  };
};

export const removeUserOrder = () => {
  return {
    type: actionTypes.REMOVE_USER_ORDER,
  };
};

export const updateUserAddress = (details, cb) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_USER, payload: true});
    http
      .put(`${API_URL}/users`, details)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_LOGIN_DATA,
          payload: res.data.user,
        });
        await dispatch({
          type: actionTypes.LOADING_USER,
          payload: false,
        });
        if (
          res.data.user &&
          res.data.user.addresses &&
          res.data.user.addresses.length > 0
        ) {
          if (cb) {
            cb(res.data.user.addresses[res.data.user.addresses.length - 1]);
          }
        }
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_USER, payload: false});
      });
  };
};

export const updateNoAuthUserAddress = (details, cb) => {
  return () => {
    axios
      .post(`${API_URL}/users/address`, details)
      .then(() => {})
      .catch(() => {});
  };
};

export const saveUserCard = (details, cb) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_USER, payload: true});
    http
      .put(`${API_URL}/users`, details)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_LOGIN_DATA,
          payload: res.data.user,
        });
        await dispatch({
          type: actionTypes.LOADING_USER,
          payload: false,
        });
        if (cb) {
          cb();
        }
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_USER, payload: false});
      });
  };
};
