export const API_URL = process.env.REACT_APP_API_URL;
// export const API_URL = 'http://localhost:8080/v1';
// export const API_URL = 'https://api.wtf.menu/v1';
export const ABLY_KEY = process.env.REACT_APP_ABLY_KEY;
export const RZP_KEY = process.env.REACT_APP_RZP_KEY;
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const GM_KEY = process.env.REACT_APP_GM_KEY;
export const GA_KEY = process.env.REACT_APP_GA_KEY;
export const MB_KEY = process.env.REACT_APP_MAPBOX_KEY;
export const UI_PATH = process.env.REACT_APP_UI_PATH;
export const TC_KEY = process.env.REACT_APP_TC_KEY;
