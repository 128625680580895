import axios from 'axios';
import {toast} from 'react-toastify';
import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {http} from '../../http';

export const setSlideIndex = (index) => ({
  type: actionTypes.SET_SLIDE_INDEX,
  payload: index,
});

export const getRestData = (restID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.SET_REST_LOADING, payload: true});
    axios
      .get(`${API_URL}/stores/${restID}`)
      .then((res) => {
        dispatch({type: actionTypes.SET_REST_DATA, payload: res.data});
        dispatch({type: actionTypes.SET_REST_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.SET_REST_LOADING, payload: false});
      });
  };
};

export const getItems = (restID, orderType) => {
  return (dispatch, getState) => {
    const veg = getState().misc.veg;
    dispatch({type: actionTypes.SET_ITEM_LOADING, payload: true});
    axios
      .get(
        `${API_URL}/micro/items/${restID}?veg=${veg}&order_type=${
          orderType === 'menu' ? 'dinein' : orderType
        }`
        // `${API_URL}/stores/${restID}/items?veg=${veg}&order_type=${
        //   orderType === 'menu' ? 'dinein' : orderType
        // }&limit=1000`
      )
      .then((res) => {
        dispatch({type: 'ITEM_NOT_AVAILABLE', payload: ''});
        dispatch({type: actionTypes.SET_ITEM_DATA, payload: res.data});
        dispatch({type: actionTypes.SET_ITEM_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.SET_ITEM_LOADING, payload: false});
      });
  };
};

export const getCategory = (restID, type) => {
  return (dispatch) => {
    dispatch({type: actionTypes.SET_CATEGORY_LOADING, payload: true});
    axios
      .get(
        `${API_URL}/stores/${restID}/category?order_type=${
          type === 'menu' ? 'dinein' : type
        }`
      )
      .then((res) => {
        dispatch({type: actionTypes.SET_CATEGORY_DATA, payload: res.data});
        dispatch({type: actionTypes.SET_CATEGORY_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.SET_CATEGORY_LOADING, payload: false});
      });
  };
};

export const getHotItems = (restID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.SET_REST_LOADING, payload: true});
    axios
      .get(`${API_URL}/stores/${restID}/category/item/most`)
      .then((res) => {
        if (res.data.top5Ordered) {
          dispatch({
            type: actionTypes.INIT_TOP_SOLD_ITEM,
            payload: res.data.top5Ordered,
          });
        }
        if (res.data.top5Rated) {
          dispatch({
            type: actionTypes.INIT_TOP_LIKED_ITEM,
            payload: res.data.top5Rated,
          });
        }
        if (res.data.mostOrderedOfCategory) {
          dispatch({
            type: actionTypes.INIT_TOP_SOLD_CATEGORY_ITEM,
            payload: res.data.mostOrderedOfCategory,
          });
        }
        dispatch({type: actionTypes.SET_REST_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.SET_REST_LOADING, payload: false});
      });
  };
};

export const getItemCombos = (restID) => {
  return (dispatch, getState) => {
    const veg = getState().misc.veg;
    dispatch({type: actionTypes.SET_ITEM_LOADING, payload: true});
    axios
      .get(`${API_URL}/items/${restID}/combos?veg=${veg}`)
      .then((res) => {
        dispatch({type: actionTypes.SET_ITEMS_COMBOS_DATA, payload: res.data});
        dispatch({type: actionTypes.SET_ITEM_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.SET_ITEM_LOADING, payload: false});
      });
  };
};

export const getRepeatOrder = (restID) => {
  return (dispatch, getState) => {
    const orderType = getState().misc.scanData && getState().misc.scanData.type;
    dispatch({type: actionTypes.SET_REST_LOADING, payload: true});
    http
      .get(
        `${API_URL}/orders/user/${restID}/prev_items?order_type=${orderType}`
      )
      .then((res) => {
        dispatch({type: actionTypes.INIT_REPEAT_ORDER, payload: res.data});
        dispatch({type: actionTypes.SET_REST_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.SET_REST_LOADING, payload: false});
      });
  };
};

export const searchItem = (value) => {
  return (dispatch, getState) => {
    const itemList = getState().source.itemList;
    const language =
      getState().source.restData && getState().source.restData.opted_languages;
    if (value.trim(' ') !== '') {
      const tempItem = [];
      if (itemList.length > 0) {
        itemList.forEach((item) => {
          if (
            item.name[
              language &&
              language.includes(window.localStorage.i18nextLng.split('-')[0])
                ? window.localStorage.i18nextLng.split('-')[0]
                : 'en'
            ]
              .toLowerCase()
              .includes(value.toLowerCase())
          ) {
            tempItem.push(item);
          }
        });
        dispatch({type: actionTypes.SET_SEARCH_DATA, payload: tempItem});
      }
    } else {
      dispatch({type: actionTypes.SET_SEARCH_DATA, payload: []});
    }
  };
};

export const ratingsPageVisibility = (status) => {
  return (dispatch) => {
    dispatch({type: actionTypes.RATINGS_PAGE_VISIBILITY, payload: status});
  };
};

export const finishDine = () => {
  return (dispatch) => {
    dispatch({type: 'ITEM_NOT_AVAILABLE', payload: ''});
    dispatch({type: actionTypes.RESET_SOURCE});
    dispatch({type: actionTypes.RESET_GROUP});
    dispatch({type: actionTypes.RESET_MISC});
  };
};

export const getTableActiveItems = () => {
  return (dispatch, getState) => {
    dispatch({type: actionTypes.SET_REST_LOADING, payload: true});
    const state = getState();
    const scanData = state.misc.scanData;
    http
      .get(`${API_URL}/orders/active/${scanData.store_id}/items`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_ACTIVE_ITEMS, payload: res.data});
        dispatch({type: actionTypes.SET_REST_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.SET_REST_LOADING, payload: false});
      });
  };
};

export const bookTable = (storeId, data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.SET_REST_LOADING, payload: true});
    http
      .post(`${API_URL}/table/booking/${storeId}`, data)
      .then(() => {
        toast.success('Table booked successfully');
        successCB();
        dispatch({type: actionTypes.SET_REST_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.SET_REST_LOADING, payload: false});
      });
  };
};
