import * as actionTypes from '../../actionTypes';

const initialState = {
  purchaseOrder: {},
};

export default function purchaseReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrder: action.payload,
      };
    case actionTypes.RESET_MISC:
      return {
        purchaseOrder: {},
      };
    default:
      return state;
  }
}
