import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import DialogContent from '@material-ui/core/DialogContent';
import {useDispatch, useSelector} from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import DashedInput from '../DashedInput';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {push} from 'connected-react-router';
// import {addUserToGroup} from '../../services/actions/groupActions';
import {useStyles} from './styles';
import {addUserEnigma} from '../../services/actions/groupActions';
import socialHelpImage from './social_code.png';

function EnigmaModal(props) {
  const classes = useStyles();
  const groupOccupied = useSelector((state) => state.group.tableOccupied);
  const enigmaPopup = useSelector((state) => state.group.enigmaPopup);
  const [fCode, setFCode] = React.useState('');
  const [fCodeVerify, setFCodeVerify] = React.useState('');
  const fCodeVerification = (value) => {
    setFCodeVerify(value);
  };
  const dispatch = useDispatch();

  const handleClick = async () => {
    if (fCodeVerify !== 'pass') {
      await dispatch(addUserEnigma(fCode, fCodeVerification));
      await setFCodeVerify('');
      await setFCode('');
      await dispatch({
        type: 'SET_TABLE_ALREADY_OCCUPIED',
        payload: false,
      });
      await dispatch({type: 'SET_ENIGMA_POPUP', payload: false});
    }
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        classes={{
          paper: classes.drawerTopCorners,
        }}
        // style={{padding: '0 !important', zIndex: 1500}}
        // maxWidth={'md'}
        open={groupOccupied || enigmaPopup}
      >
        <DialogTitle>
          <Typography className={classes.dialogTitleGroup}>
            {enigmaPopup ? 'Enter SOCIAL CODE to join' : 'Table Occupied'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContentGroup}>
            <Typography
              style={{
                marginTop: 10,
                fontSize: 14,
              }}
            >
              Ask for <span style={{color: 'red'}}>SOCIAL CODE</span> and enter
              the code below to join table.
            </Typography>
          </DialogContentText>
          <DashedInput
            length={5}
            value={fCode}
            setValue={setFCode}
            OTPFocus
            handleClickSubmit={handleClick}
          />
        </DialogContent>
        <DialogActions style={{padding: 16}}>
          <Button
            disabled={
              fCode.length > 0 &&
              (fCodeVerify === '' || fCodeVerify === 'pending')
            }
            onClick={() => {
              if (enigmaPopup) {
                dispatch({type: 'SET_ENIGMA_POPUP', payload: false});
              } else {
                if (fCodeVerify === '' || fCodeVerify === 'fail') {
                  dispatch({
                    type: 'RESET',
                    userInitial: {user: props.userState},
                  });
                  dispatch({
                    type: 'SET_TABLE_ALREADY_OCCUPIED',
                    payload: false,
                  });
                  dispatch(push('/'));
                } else {
                  dispatch({
                    type: 'SET_TABLE_ALREADY_OCCUPIED',
                    payload: false,
                  });
                }
              }
            }}
          >
            Cancel
          </Button>
          <Button
            style={{boxShadow: 'none'}}
            variant="contained"
            color={fCodeVerify === 'pass' ? 'secondary' : 'primary'}
            disabled={fCode.length < 5 || fCodeVerify === 'pending'}
            onClick={handleClick}
          >
            {fCodeVerify === ''
              ? 'Verify'
              : fCodeVerify === 'pass'
              ? 'Verified'
              : fCodeVerify === 'fail'
              ? 'Retry'
              : 'verifying'}
          </Button>
        </DialogActions>
        <div style={{padding: 16, fontSize: 12}}>
          <div>You can find SOCIAL CODE here :</div>
          <img
            src={socialHelpImage}
            alt={''}
            style={{width: '100%', marginTop: 8}}
          />
        </div>
      </Drawer>
    </>
  );
}

export default EnigmaModal;
