import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    display: 'flex',
  },
  display: {
    borderBottom: `2px solid ${theme.palette.text.secondary}`,
    width: '100%',
    height: '2em',
    display: 'flex',
    margin: '2px 8px 0px 0px',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.text.primary,
    position: 'relative',
    '&:first-child': {
      borderLeft: 'none',
      marginLeft: 0,
    },
    '&:last-child': {
      borderRight: 'none',
      marginRight: 0,
    },
  },
  inactive: {
    borderBottom: '2px solid rgba(0, 0, 0, 0.2)',
  },
  input: {
    position: 'absolute',
    border: 'none',
    fontSize: 18,
    textAlign: 'center',
    backgroundColor: 'transparent',
    outline: 'none',
    caretColor: '#F6A61E',
  },
  shadows: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
  focused: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.primary,
    marginBottom: '-0.11em',
  },
  notFocused: {
    borderBottom: '2px solid rgba(0, 0, 0, 0.2)',
  },
}));
