import * as actionTypes from '../../actionTypes';

const initialState = {
  loyalties: [],
  loyaltiesPending: false,
  loyaltiesError: '',
};

export default function loyaltyReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_USER_LOYALTY_PENDING:
      return {
        ...state,
        loyaltiesPending: true,
      };
    case actionTypes.GET_USER_LOYALTY_SUCCESS:
      return {
        ...state,
        loyaltiesPending: false,
        loyalties: action.payload,
      };
    case actionTypes.GET_USER_LOYALTY_FAILED:
      return {
        ...state,
        loyaltiesPending: false,
        loyaltiesError: action.payload,
      };
    default:
      return state;
  }
}
