import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

function ScannedRouterHoc({component: Component, ...rest}) {
  const scannedData = useSelector((state) => state.misc.scanData);
  const paid = useSelector(
    (state) => state.group.order && state.group.order.paid
  );
  return (
    <Route
      {...rest}
      render={(prop) =>
        scannedData.type && !paid ? (
          <Component {...prop} />
        ) : (
          <Redirect to={`/`} />
        )
      }
    />
  );
}

export default ScannedRouterHoc;
