import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

function PaymentRouterHoc({component: Component, ...rest}) {
  const orderData = useSelector(
    (state) => state.group.order && state.group.order._id
  );
  const paid = useSelector(
    (state) => state.group.order && state.group.order.paid
  );
  return (
    <Route
      {...rest}
      render={(prop) =>
        orderData && !paid ? <Component {...prop} /> : <Redirect to={`/`} />
      }
    />
  );
}

export default PaymentRouterHoc;
