import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import authReducers from './authReducers';
import cartReducers from './cartReducers';
import groupReducers from './groupReducers';
import miscReducers from './miscReducers';
import sourceReducers from './sourceReducers';
import profileReducers from './profileReducers';
import orderReducers from './orderReducers';
import rewardsReducers from './rewardsReducers';
import loyaltyReducers from './loyaltyReducers';
import storeReducers from './storeReducers';
import purchaseReducers from './purchaseReducers';
import storeGroupReducers from './storeGroupReducers';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducers,
    cart: cartReducers,
    group: groupReducers,
    misc: miscReducers,
    loyalty: loyaltyReducers,
    rewards: rewardsReducers,
    order: orderReducers,
    profile: profileReducers,
    source: sourceReducers,
    stores: storeReducers,
    purchase: purchaseReducers,
    storeGroup: storeGroupReducers,
  });

export default reducers;
