import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 32px)',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    height: theme.spacing(17),
    position: 'fixed',
    bottom: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(7, 8),
    boxShadow: `0px 0px 2px ${theme.palette.text.primary}`,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  customButton: {
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 12,
    letterSpacing: 1.5,
    color: theme.palette.text.secondary,
  },
  activeButton: {
    color: theme.palette.text.primary,
  },
  billMarked: {
    height: 10,
    width: 10,
    backgroundColor: '#F7C945',
    borderRadius: 5,
    position: 'absolute',
    top: -5,
    right: -5,
  },
  disabled: {
    opacity: 0.4,
  },
}));
