import React from 'react';
import {useStyles} from './styles';
import {useTranslation} from 'react-i18next';
import Drawer from '@material-ui/core/Drawer';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FastFoodIcon from '@material-ui/icons/Fastfood';
import {setServiceModal} from '../../services/actions/miscActions';
import AppBar from '@material-ui/core/AppBar';
import {getService} from '../../services/actions/groupActions';

function ServiceModal() {
  const classes = useStyles();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.misc.serviceModal);
  const services = useSelector(
    (state) =>
      state.source.restData.preferences &&
      state.source.restData.preferences.services
  );
  const [selectedServices, setSelectedServices] = React.useState([]);
  const handleClose = () => {
    dispatch(setServiceModal(false));
  };

  const handleServices = (item) => {
    if (selectedServices.find((x) => x._id === item._id)) {
      setSelectedServices(selectedServices.filter((x) => x._id !== item._id));
    } else {
      setSelectedServices([...selectedServices, item]);
    }
  };

  return (
    <Drawer
      classes={{
        paper: classes.drawer,
      }}
      anchor={'bottom'}
      open={isOpen}
      onClose={handleClose}
    >
      <AppBar
        className={`${classes.toolbar} ${classes.drawer}`}
        style={{boxShadow: 'none'}}
      >
        <Toolbar>
          <div className={classes.title}>
            <Typography
              variant="h6"
              style={{
                fontSize: 14,
                textTransform: 'uppercase',
                fontWeight: 600,
                color: '#FFFFFF',
              }}
            >
              {t('services_title')}
            </Typography>
            <Typography variant="h6" style={{fontSize: 12, color: '#FFFFFF'}}>
              {t('services_sub')}
            </Typography>
          </div>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <>
        <Grid
          item
          xs={12}
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            marginTop: 80,
            marginBottom: 100,
          }}
        >
          <Grid container justify="center" spacing={2}>
            {services &&
              services
                .filter((a) => a.active)
                .map((value) => (
                  <Grid xs={6} key={value._id} item>
                    <Paper
                      className={`${classes.paper} ${
                        selectedServices.find((o) => o._id === value._id)
                          ? classes.paperActive
                          : ''
                      }`}
                      onClick={() => handleServices(value)}
                    >
                      {value.icon_url ? (
                        <img
                          src={value.icon_url}
                          alt="s_icon"
                          width={42}
                          height={42}
                        />
                      ) : (
                        <FastFoodIcon style={{fontSize: 42, color: 'grey'}} />
                      )}
                      <Typography style={{marginTop: 8}}>
                        {value.name}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
          </Grid>
        </Grid>
        <div className={classes.buttonDiv}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            onClick={async () => {
              await dispatch(getService(selectedServices));
              await setSelectedServices([]);
            }}
          >
            {t('continue')}
          </Button>
        </div>
      </>
    </Drawer>
  );
}

export default withRouter(ServiceModal);
