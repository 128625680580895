import * as actionTypes from '../../actionTypes';

const initialState = {
  orders: {
    result: [],
    __metadata: {},
  },
  orderPending: false,
  orderError: '',
  orederDetails: null,
  orederDetailsPending: false,
  orderDetailsError: null,
};

export default function orderReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PREV_ORDERS_PENDING:
      return {
        ...state,
        orderPending: true,
      };
    case actionTypes.GET_PREV_ORDERS_SUCCESS:
      return {
        ...state,
        orderPending: false,
        orders: action.payload,
      };
    case actionTypes.GET_PREV_ORDERS_SUCCESS_PAGINATION: {
      const temp = {...state.orders};
      if (temp.result) {
        action.payload.result &&
          action.payload.result.length > 0 &&
          action.payload.result.forEach((res) => {
            temp.result.push(res);
          });
      }
      temp['__metadata'] = action.payload.__metadata;
      return {
        ...state,
        orders: temp,
      };
    }
    case actionTypes.GET_PREV_ORDERS_FAILED:
      return {
        ...state,
        orderPending: false,
        orderError: action.payload,
      };
    case actionTypes.GET_ORDER_DETAILS_PENDING:
      return {
        ...state,
        orderDetailsPending: true,
      };
    case actionTypes.GET_ORDER_DETAILS_SUCCESS:
      let tempOrders = {...state.orders};
      if (tempOrders.result && tempOrders.result.length > 0) {
        let tempIndex = tempOrders.result.findIndex(
          (a) => a._id === action.payload._id
        );
        if (tempIndex > -1) {
          tempOrders.result[tempIndex] = {
            ...action.payload,
            store_id: tempOrders.result[tempIndex].store_id,
          };
        } else {
          tempOrders.result.push(action.payload);
        }
      }
      return {
        ...state,
        orderDetailsPending: false,
        orders: tempOrders,
        orderDetails: action.payload,
      };
    case actionTypes.GET_ORDER_DETAILS_FAILED:
      return {
        ...state,
        orderDetailsPending: false,
        orderDetailsError: action.payload,
      };
    default:
      return state;
  }
}
