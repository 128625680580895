export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const UPDATE_PROF_PIC_PENDING = 'UPDATE_PROF_PIC_PENDING';
export const UPDATE_PROF_PIC_SUCCESS = 'UPDATE_PROF_PIC_SUCCESS';
export const UPDATE_PROF_PIC_FAILED = 'UPDATE_PROF_PIC_FAILED';
export const ADD_USER_TO_GROUP_PENDING = 'ADD_USER_TO_GROUP_PENDING';
export const ADD_USER_TO_GROUP_SUCCESS = 'ADD_USER_TO_GROUP_SUCCESS';
export const ADD_USER_TO_GROUP_FAILED = 'ADD_USER_TO_GROUP_FAILED';
export const SET_VOUCHERS_USER_SHAREABLE_LOADING =
  'SET_VOUCHERS_USER_SHAREABLE_LOADING';
export const SET_VOUCHERS_USER_SHAREABLE = 'SET_VOUCHERS_USER_SHAREABLE';
