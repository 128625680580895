import {http} from '../../http';
import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {
  getCategory,
  getItems,
  getHotItems,
  getItemCombos,
} from '../sourceActions';
import {setOrderMode, setScanData, setScanned} from '../miscActions';

export const initQMS = (id) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .get(`${API_URL}/stores/${id}`)
      .then(async (res) => {
        await dispatch({type: actionTypes.SET_REST_DATA, payload: res.data});
        if (res.data && res.data._id) {
          await dispatch({type: 'ITEM_NOT_AVAILABLE', payload: ''});
          await dispatch(getItems(id, 'dinein'));
          await dispatch(getItemCombos(id));
          await dispatch(getCategory(id, 'dinein'));
          await dispatch(getHotItems(id));
          await dispatch(
            setScanData({
              type: 'qms',
              store_group_id: '',
              store_id: res.data._id,
              table_id: '',
              source: 'wtf',
              scheduleDelivery: null,
            })
          );
          await dispatch(setOrderMode('qms'));
          await dispatch(setScanned(true));
        }
        await dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const addToQueue = (id, data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .post(`${API_URL}/waiting_queue/${id}/add`, data)
      .then(() => {
        successCB();
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const getQueueInfo = (storeID, userID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .get(`${API_URL}/waiting_queue/${storeID}/${userID}/count`)
      .then((res) => {
        dispatch({type: actionTypes.QUEUE_STATUS, payload: res.data});
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};
