import * as actionTypes from '../../actionTypes';

const initialState = {
  user: {},
  profilePending: false,
  profileError: '',
  shareableVouchers: [],
  shareableVoucherLoading: false,
};

export default function profileReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_USER_PENDING:
      return {
        ...state,
        profilePending: true,
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        profilePending: false,
        user: action.payload,
      };
    case actionTypes.UPDATE_USER_FAILED:
      return {
        ...state,
        profilePending: false,
        profileError: action.payload,
      };
    case actionTypes.SET_VOUCHERS_USER_SHAREABLE:
      return {
        ...state,
        shareableVouchers: action.payload,
      };
    case actionTypes.SET_VOUCHERS_USER_SHAREABLE_LOADING:
      return {
        ...state,
        shareableVoucherLoading: action.payload,
      };
    default:
      return state;
  }
}
