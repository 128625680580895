import * as actionTypes from '../../actionTypes';

const initialState = {
  scanData: {},
  scanSuccess: false,
  scanErrored: false,
  scanPending: false,
  orderMode: 'dinein',
  loadingFC: false,
  snackMessage: {},
  restList: [],
  veg: false,
  serviceModal: false,
  languageModal: false,
  unSupportedLang: false,
  subscriptionPayment: {},
  queue: {},
  songs: [],
  itemNotAvailable: '',
  getFeedBack: false,
  ratingsLoading: false,
  ratingsOption: {},
  trackOrder: {},
};

export default function miscReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_SCAN_DATA:
      return {
        ...state,
        scanData: action.payload,
      };
    case actionTypes.SET_SCANNED_store_id:
      let scanRest = {...state.scanData};
      scanRest['store_id'] = action.payload.restID;
      scanRest['type'] = action.payload.type;
      return {
        ...state,
        scanData: scanRest,
      };
    case actionTypes.SET_SCAN_SUCCESS:
      return {
        ...state,
        scanSuccess: action.payload,
      };
    case actionTypes.SET_SCAN_ERROR:
      return {
        ...state,
        scanErrored: action.payload,
      };
    case actionTypes.SET_SCAN_PENDING:
      return {
        ...state,
        scanPending: action.payload,
      };
    case actionTypes.SET_ORDER_MODE:
      return {
        ...state,
        orderMode: action.payload,
      };
    case actionTypes.SET_VEG:
      return {
        ...state,
        veg: action.payload,
      };
    case actionTypes.SET_SNACK_MESSAGE:
      return {
        ...state,
        snackMessage: action.payload,
      };
    case actionTypes.FOOD_COURT_LOADING:
      return {
        ...state,
        loadingFC: action.payload,
      };
    case actionTypes.SET_FOOD_COURT_DATA:
      return {
        ...state,
        restList: action.payload,
      };
    case actionTypes.SET_LANGUAGE_MODAL:
      return {
        ...state,
        languageModal: action.payload,
      };
    case actionTypes.SET_UNSUPPORTED_LANG:
      return {
        ...state,
        unSupportedLang: action.payload,
      };
    case actionTypes.SET_SERVICE_MODAL: {
      return {
        ...state,
        serviceModal: action.payload,
      };
    }
    case actionTypes.INIT_SUBSCRIPTION_PAYMENT:
      return {
        ...state,
        subscriptionPayment: action.payload,
      };
    case actionTypes.QUEUE_STATUS:
      return {
        ...state,
        queue: action.payload,
      };
    case actionTypes.INIT_JUKEBOX:
      return {
        ...state,
        songs: action.payload,
      };
    case 'ITEM_NOT_AVAILABLE':
      return {
        ...state,
        itemNotAvailable: action.payload,
      };
    case actionTypes.SET_FEEDBACK_STATUS:
      return {
        ...state,
        getFeedBack: action.payload,
      };
    case actionTypes.INIT_RATINGS_MISC:
      return {
        ...state,
        ratingsOption: action.payload,
      };
    case actionTypes.LOADING_RATINGS_MISC:
      return {
        ...state,
        ratingsLoading: action.payload,
      };
    case actionTypes.SAVE_TRACK_ORDER:
      return {
        ...state,
        trackOrder: action.payload,
      };
    case actionTypes.RESET_MISC:
      return {
        scanData: {},
        scanSuccess: false,
        scanErrored: false,
        scanPending: false,
        orderMode: 'dinein',
        loadingFC: false,
        snackMessage: {},
        restList: [],
        veg: false,
        languageModal: false,
        unSupportedLang: false,
        serviceModal: false,
        subscriptionPayment: {},
        queue: {},
        songs: [],
        itemNotAvailable: '',
        getFeedBack: false,
        ratingsLoading: false,
        ratingsOption: {},
        trackOrder: {},
      };
    default:
      return state;
  }
}
