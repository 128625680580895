import * as actions from '../../actionTypes';
import {API_URL} from '../../../utils/constants';
import {http} from '../../http';

export const updateUser = (user) => async (dispatch) => {
  dispatch({type: actions.UPDATE_USER_PENDING});
  try {
    const res = await http.put(`${API_URL}/users`, user);
    dispatch({type: actions.UPDATE_USER_SUCCESS, payload: res.data.user});
    dispatch({type: actions.SET_USER, payload: res.data.user});
  } catch (err) {
    dispatch({type: actions.UPDATE_USER_FAILED, payload: err});
  }
};

export const updateProfilePicture = (image) => async (dispatch) => {
  dispatch({type: actions.UPDATE_PROF_PIC_PENDING});
  try {
    const res = await http.post(`${API_URL}/users/profileImage`, image);
    dispatch({type: actions.UPDATE_PROF_PIC_SUCCESS, payload: res.data.User});
    dispatch({type: actions.SET_USER, payload: res.data.User});
  } catch (err) {
    dispatch({type: actions.UPDATE_PROF_PIC_FAILED, payload: err});
  }
};

export const getUserSharableVouchers = () => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_VOUCHERS_USER_SHAREABLE_LOADING,
      payload: true,
    });
    http
      .get(`${API_URL}/rewards/user/shared`)
      .then((res) => {
        dispatch({
          type: actions.SET_VOUCHERS_USER_SHAREABLE,
          payload: res.data,
        });
        dispatch({
          type: actions.SET_VOUCHERS_USER_SHAREABLE_LOADING,
          payload: false,
        });
      })
      .catch(() => {
        dispatch({
          type: actions.SET_VOUCHERS_USER_SHAREABLE_LOADING,
          payload: false,
        });
      });
  };
};
