import * as actions from '../../actionTypes';
import {API_URL} from '../../../utils/constants';
import {http} from '../../http';
import {updateCurrentOrder} from '../groupActions';

export const getPreviousOrders = () => async (dispatch, getState) => {
  dispatch({type: actions.GET_PREV_ORDERS_PENDING});
  const userID =
    getState().auth.userData &&
    getState().auth.userData.user &&
    getState().auth.userData.user._id;
  try {
    const res = await http.get(`${API_URL}/orders/user/${userID}`);
    dispatch({type: actions.GET_PREV_ORDERS_SUCCESS, payload: res.data});
  } catch (err) {
    dispatch({type: actions.GET_PREV_ORDERS_FAILED, payload: err});
  }
};

export const getPreviousOrdersPagination = (url) => (dispatch) => {
  http
    .get(url)
    .then((res) =>
      dispatch({
        type: actions.GET_PREV_ORDERS_SUCCESS_PAGINATION,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({type: actions.GET_PREV_ORDERS_FAILED, payload: err})
    );
};

export const getOrderDetails = (orderID) => async (dispatch, getState) => {
  dispatch({type: actions.GET_ORDER_DETAILS_PENDING});
  const currentOrder = getState().order.order && getState().order.order._id;
  try {
    const res = await http.get(`${API_URL}/orders/${orderID}`);
    if (currentOrder === orderID && res.data.paid) {
      updateCurrentOrder(orderID);
    }
    dispatch({type: actions.GET_ORDER_DETAILS_SUCCESS, payload: res.data});
  } catch (err) {
    dispatch({type: actions.GET_ORDER_DETAILS_FAILED, payload: err});
  }
};

export const resetOrderDetails = () => {
  return {type: actions.GET_ORDER_DETAILS_SUCCESS, payload: null};
};
