import axios from 'axios';
import {API_URL, RZP_KEY, UI_PATH} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {replace} from 'connected-react-router';
import {getItems, getItemCombos} from '../sourceActions';
import {resetCart} from '../cartActions';
import {fetchUserDetails} from '../authActions';
import {toast} from 'react-toastify';
import {http} from '../../http';

export const setScanned = (status) => ({
  type: actionTypes.SET_SCAN_SUCCESS,
  payload: status,
});

export const setLanguageModal = (status) => ({
  type: actionTypes.SET_LANGUAGE_MODAL,
  payload: status,
});

export const unSupportedlanguage = (status) => ({
  type: actionTypes.SET_UNSUPPORTED_LANG,
  payload: status,
});

export const setErrored = (status) => ({
  type: actionTypes.SET_SCAN_ERROR,
  payload: status,
});

export const setScanData = (scanData) => ({
  type: actionTypes.SET_SCAN_DATA,
  payload: scanData,
});

export const updateScannedRestID = (restID, type) => ({
  type: actionTypes.SET_SCANNED_store_id,
  payload: {restID: restID, type: type},
});

export const setOrderMode = (mode) => ({
  type: actionTypes.SET_ORDER_MODE,
  payload: mode,
});

export const setServiceModal = (status) => ({
  type: actionTypes.SET_SERVICE_MODAL,
  payload: status,
});

export const ablyAck = (id) => {
  return () => {
    axios
      .put(`${API_URL}/ably/${id}`, {ack: true})
      .then(() => console.log('acknowledged'))
      .catch(() => console.log('unable to ack'));
  };
};

export const setFeedBackStatus = (status) => ({
  type: actionTypes.SET_FEEDBACK_STATUS,
  payload: status,
});

export const addFeedBack = (option) => {
  return () => {
    http
      .post(`${API_URL}/feedback`, {feedback_option: option, __type: 'crypto'})
      .then(() => console.log('feedback provided'))
      .catch(() => console.log('error in providing feedback'));
  };
};

export const scanQR = (scanData) => {
  return async (dispatch, getState) => {
    const state = getState();
    await dispatch({type: actionTypes.RESET_SOURCE});
    await dispatch({type: actionTypes.RESET_GROUP});
    await dispatch({type: actionTypes.SET_SCAN_PENDING, payload: true});
    await dispatch(resetCart());
    await dispatch(setScanData(scanData));
    await dispatch(setOrderMode(scanData.type));
    await dispatch(setScanned(true));
    await dispatch({type: actionTypes.SET_SCAN_PENDING, payload: false});
    if (scanData.type === 'foodcourt') {
      await dispatch(replace('/food-court'));
      await dispatch(fetchFoodCourt(scanData.store_group_id));
    } else {
      await dispatch(replace('/home'));
    }
    if (
      state.auth.userData &&
      state.auth.userData.user &&
      state.auth.userData.user._id
    ) {
      await dispatch(fetchUserDetails());
    }
  };
};

export const fetchFoodCourt = (id) => {
  return (dispatch) => {
    dispatch({type: actionTypes.FOOD_COURT_LOADING, payload: true});
    axios
      .get(`${API_URL}/store_groups/${id}/stores`)
      .then((res) => {
        dispatch({type: actionTypes.SET_FOOD_COURT_DATA, payload: res.data});
        dispatch({type: actionTypes.FOOD_COURT_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.FOOD_COURT_LOADING, payload: false});
      });
  };
};

export const setVegIcon = (value) => {
  return async (dispatch, getState) => {
    const restID =
      getState().misc.scanData && getState().misc.scanData.store_id;
    const orderType = getState().misc.scanData && getState().misc.scanData.type;
    await dispatch({type: actionTypes.SET_VEG, payload: value});
    await dispatch(
      getItems(restID, orderType === 'qms' ? 'dinein' : orderType)
    );
    await dispatch(getItemCombos(restID));
  };
};

export const setSnackMessage = (
  message,
  alertType,
  verticalPos,
  horizontalPos,
  duration
) => {
  return async (dispatch) => {
    let data = {};
    data['message'] = message;
    data['type'] = alertType;
    data['verticalPos'] = verticalPos;
    data['horizontalPos'] = horizontalPos;
    data['duration'] = duration;
    dispatch({type: actionTypes.SET_SNACK_MESSAGE, payload: data});
  };
};

export const getSubscriptionPayment = (ID, failureCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: true});
    axios
      .get(`${API_URL}/payment_link/${ID}`)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_SUBSCRIPTION_PAYMENT,
          payload: res.data,
        });
        dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: false});
      })
      .catch(() => {
        failureCB();
        dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: false});
      });
  };
};

export const makeSubscriptionPayment = (ID, data, cb) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: true});
    axios
      .post(`${API_URL}/payment_link/${ID}/payment`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.LOADING_SEND_TO_KITCHEN,
          payload: false,
        });
        if (data.onlineMode === 'card' && cb) {
          await cb(res.data.payment_details);
        }
        if (data.onlineMode === 'upi') {
          if (
            data.payerVpa &&
            res.data.payment_details &&
            res.data.payment_details &&
            res.data.payment_details.aggregator === 'bharatpe'
          ) {
            toast.success(
              'Please open your UPI app and accept the payment request'
            );
          }
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          toast.error(err.response.data.message);
        }
        dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: false});
      });
  };
};

export const subscriptionRazorPayCardCheckout = (rzp, card) => {
  return (dispatch) => {
    const razorpay = new window.Razorpay({
      key: RZP_KEY,
      callback_url: `${API_URL}/payments/razorpay/checkout?url=${UI_PATH}`,
      redirect: false,
    });
    const data = {
      order_id: rzp.aggregator_id,
      amount: rzp.amount,
      method: 'card',
      'card[name]': card.name,
      'card[number]': card.number,
      'card[cvv]': card.cvv,
      'card[expiry_month]': card.validity.split('/')[0],
      'card[expiry_year]': card.validity.split('/')[1],
    };
    if (razorpay) {
      razorpay.createPayment(data);
      razorpay.on('payment.success', async function (res) {
        await dispatch(replace('/'));
        await toast.success('Payment successfull');
      });
      razorpay.on('payment.error', function (err) {
        toast.error(err.error.description);
      });
    }
  };
};

export const getStoreSongQueue = (storeID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: true});
    axios
      .get(`${API_URL}/jukebox/store/${storeID}/queue`)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_JUKEBOX,
          payload: res.data,
        });
        dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: false});
      });
  };
};

export const voteSong = (songID, storeID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: true});
    http
      .post(`${API_URL}/jukebox/${storeID}/queue/${songID}`)
      .then(() => {
        dispatch(getStoreSongQueue(storeID));
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: false});
      });
  };
};

export const fetchRateOrder = (orderID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.INIT_RATINGS_MISC, payload: {}});
    dispatch({type: actionTypes.LOADING_RATINGS_MISC, payload: true});
    http
      .get(`${API_URL}/ratings/order/${orderID}`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_RATINGS_MISC, payload: res.data});
        dispatch({type: actionTypes.LOADING_RATINGS_MISC, payload: false});
      })
      .catch(() => {
        dispatch({
          type: actionTypes.INIT_RATINGS_MISC,
          payload: {orderNotFound: true},
        });
        dispatch({type: actionTypes.LOADING_RATINGS_MISC, payload: false});
      });
  };
};

export const getTrackOrder = (orderID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .get(`${API_URL}/orders/${orderID}`)
      .then((res) => {
        dispatch({type: actionTypes.SAVE_TRACK_ORDER, payload: res.data});
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};
