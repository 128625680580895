export const GET_ORDER_PENDING = 'GET_ORDER_PENDING';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILED = 'GET_ORDER_FAILED';
export const SELECTED_ORDER_ADDRESS = 'SELECTED_ORDER_ADDRESS';

export const GET_PREV_ORDERS_PENDING = 'GET_PREV_ORDERS_PENDING';
export const GET_PREV_ORDERS_SUCCESS = 'GET_PREV_ORDERS_SUCCESS';
export const GET_PREV_ORDERS_FAILED = 'GET_PREV_ORDERS_FAILED';
export const GET_PREV_ORDERS_SUCCESS_PAGINATION =
  'GET_PREV_ORDERS_SUCCESS_PAGINATION';

export const GET_ORDER_DETAILS_PENDING = 'GET_ORDER_DETAILS_PENDING';
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';
export const GET_ORDER_DETAILS_FAILED = 'GET_ORDER_DETAILS_FAILED';
