import React from 'react';

function HomeIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.02754 12.9853L2.92057 14.0809L2.32244 19.8252C2.2896 20.0678 2.3251 20.3069 2.29862 20.5467C2.12476 22.1204 2.22432 23.9337 4.29312 23.9983C5.30481 24.0299 6.0197 23.6082 6.33103 22.9552C6.73113 22.116 6.44044 20.4009 6.39582 19.3512L5.86021 14.3042L4.34528 13.5014C4.48599 13.3682 5.4676 12.8932 5.71467 12.8178C5.71467 12.3182 5.5443 11.7854 5.5443 11.2649C6.52287 11.2428 7.41403 10.3001 7.76689 9.49471C8.1055 8.72184 8.15893 8.06912 8.28788 7.32545C8.40518 6.64898 8.38709 4.05792 8.38603 3.29164C8.38521 2.68786 8.2249 1.32997 7.97301 1.01336C7.75275 0.736516 7.45818 0.530069 7.17297 0.865643C6.85971 1.23422 6.70463 4.29841 6.60013 5.04785C6.4653 6.01477 6.46351 6.73054 5.82826 6.333C5.01809 5.82599 5.04165 1.56649 4.74099 1.00797C4.42495 0.420848 3.70063 0.544935 3.56376 1.92469C3.47747 2.79467 3.25984 5.50193 3.05488 6.05597C2.29542 8.10922 1.94611 3.11702 1.94203 3.08858C1.8071 2.14797 1.98143 1.246 1.55823 0.836561C1.23173 0.520683 0.913361 0.728831 0.817246 1.04397C0.710266 1.39477 0.721073 3.05502 0.668138 3.53137C0.501143 5.03358 0.519351 7.01848 0.712963 8.51589C0.763077 8.90342 0.861468 9.23157 1.01832 9.52656C1.3165 10.0874 1.7142 10.6025 2.29965 10.8708C2.55598 10.9882 2.92408 11.1963 3.21565 11.2649C3.16024 11.505 3.11881 11.8469 3.10206 12.125L3.02754 12.9853Z"
        fill="#E53A35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9602 3.351C15.2 3.351 15.3487 4.76557 15.9455 5.1784C16.2116 5.36243 16.5254 5.41549 16.9602 5.41549V9.83121C16.9602 11.2617 19.1753 11.541 19.1753 9.65917V5.41549C19.8756 5.41549 20.8792 5.39082 20.8792 4.32589C20.8792 3.37616 19.7752 3.351 19.1753 3.351C19.1753 1.74589 19.1938 2.16465 19.9937 1.94079C20.7977 1.71577 20.9099 0.174913 19.736 0.0320251C18.7712 -0.0854309 18.017 0.103701 17.4048 0.875142C16.8393 1.58756 16.9602 2.49828 16.9602 3.351Z"
        fill="#E53A35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.71457 12.8178C5.4675 12.8932 4.48589 13.3682 4.34518 13.5014L5.86011 14.3042C9.49038 14.3042 20.635 14.7572 23.6876 13.7565C24.5699 13.4673 23.3298 13.2278 23.0709 13.1808C18.3657 12.3267 11.7879 12.7755 6.90746 12.756C6.42578 12.7541 6.11727 12.8052 5.71457 12.8178Z"
        fill="#F1DEDE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2237 3.29362C10.2749 3.31497 9.91742 3.62212 9.91742 4.49791C9.91742 5.08085 10.6329 5.34483 11.2237 5.35812V9.77384C11.2237 10.2741 11.5834 10.9781 12.246 10.9781C13.0088 10.9781 13.4388 10.5996 13.4388 9.77384V5.35812C14.4694 5.35812 15.1426 5.29793 15.1426 4.15383C15.1426 3.51183 14.4043 3.29362 13.7795 3.29362H13.4388C13.4388 2.44729 13.658 0.713013 12.4164 0.713013C10.9806 0.713013 11.2237 2.29276 11.2237 3.29362Z"
        fill="#E53A35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.92041 14.081L3.02739 12.9854C2.81205 12.9854 -1.39549 13.3033 0.480509 13.7971C1.17202 13.9791 2.22641 14.1056 2.92041 14.081Z"
        fill="#F1DEDE"
      />
    </svg>
  );
}

export default HomeIcon;
