const initialState = {
  storeGroupDetails: {},
  storeList: [],
  loadingDelivery: false,
  delivery: [],
  takeaway: [],
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case 'INIT_VISIBLE_STORE_GROUP_DETAILS':
      return {
        ...state,
        storeGroupDetails: payload.storeGroup,
        storeList: payload.result,
      };
    case 'STORE_GROUP_DELIVERY_LOADING':
      return {...state, loadingDelivery: payload};
    case 'INIT_STORE_GROUP_DELIVERY':
      return {...state, delivery: payload};
    case 'INIT_STORE_GROUP_TAKEAWAY':
      return {...state, takeaway: payload};
    case 'STORE_GROUP_RESET':
      return {
        storeGroupDetails: {},
        storeList: [],
        loadingDelivery: false,
        delivery: [],
        takeaway: [],
      };
    default:
      return state;
  }
};
