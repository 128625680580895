import {makeStyles} from '@material-ui/core/styles';
import check from './assets/check.png';
export const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: '#4285F4 !important',
    paddingLeft: '16px !important',
    top: 'auto !important',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    display: 'flex',
    height: 80,
    width: 'auto',
    margin: 8,
    boxShadow: 'none',
    border: '1px solid lightgrey',
    padding: 16,
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    borderRadius: 10,
  },
  drawer: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  paperActive: {
    border: `2px solid ${theme.palette.accent}`,
    backgroundImage: `url('${check}')`,
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    // boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    // paddingRight: '35px',
    // paddingTop: '5px',
    // paddingBottom: '5px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#FFFFFF',
  },
  title: {
    flexGrow: 1,
  },
  buttonDiv: {
    borderTop: `1px solid lightgrey`,
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.palette.background.default,
  },
  buttonStyle: {
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
    boxShadow: 'none !important',
    margin: theme.spacing(8),
    width: 'calc(100vw - 32px)',
  },
  positionChange: {
    position: 'block !important',
  },
}));
