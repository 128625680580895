import * as actionTypes from '../../actionTypes';

const initialState = {
  rewards: {
    result: [],
    __metadata: {},
  },
  rewardsPending: false,
  rewardsError: '',
  fetchRewards: {},
  fetchRewardsPending: false,
  fetchRewardsError: '',
  rewardsBanner: [],
  discountStore: {},
  discountVoucher: {},
  crm: {},
  cashbackApplied: false,
  discountVoucherError: false,
  discountStoreError: false,
};

export default function rewardsReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_USER_REWARDS_PENDING:
      return {
        ...state,
        rewardsPending: true,
      };
    case actionTypes.GET_USER_REWARDS_SUCCESS_PAGINATION: {
      const temp = {...state.rewards};
      if (temp.result) {
        action.payload.result &&
          action.payload.result.length > 0 &&
          action.payload.result.forEach((res) => {
            temp.result.push(res);
          });
      }
      temp['__metadata'] = action.payload.__metadata;
      return {
        ...state,
        rewards: temp,
      };
    }
    case actionTypes.GET_USER_REWARDS_SUCCESS:
      return {
        ...state,
        rewardsPending: false,
        rewards: action.payload,
      };
    case actionTypes.GET_USER_REWARDS_FAILED:
      return {
        ...state,
        rewardsPending: false,
        rewardsError: action.payload,
      };
    case actionTypes.GET_BANNER_VOUCHER:
      return {
        ...state,
        rewardsBanner: action.payload,
      };
    case actionTypes.GET_REWARDS_PENDING:
      return {
        ...state,
        fetchRewardsPending: true,
        fetchRewards: {},
      };
    case actionTypes.GET_REWARDS_SUCCESS:
      return {
        ...state,
        fetchRewardsPending: false,
        fetchRewards: action.payload,
      };
    case actionTypes.GET_REWARDS_FAILED:
      return {
        ...state,
        fetchRewards: {},
        fetchRewardsPending: false,
        fetchRewardsError: action.payload,
      };
    case actionTypes.GET_DISCOUNT_STORE:
      return {
        ...state,
        discountStore: action.payload,
        fetchRewardsPending: false,
        fetchRewards: {},
      };
    case actionTypes.GET_DISCOUNT_VOUCHER:
      return {
        ...state,
        discountVoucher: action.payload,
      };
    case actionTypes.CASHBACK_APPLIED:
      return {
        ...state,
        cashbackApplied: action.payload,
      };
    case actionTypes.GET_CRM_DETAILS:
      return {
        ...state,
        crm: action.payload,
      };
    case actionTypes.DISCOUNT_STORE_ERROR:
      return {
        ...state,
        discountStoreError: true,
      };
    case actionTypes.DISCOUNT_VOUCHER_ERROR:
      return {
        ...state,
        discountVoucherError: true,
      };
    case 'RESET_REWARDS':
      return {
        rewards: {
          result: [],
          __metadata: {},
        },
        rewardsPending: false,
        rewardsError: '',
        fetchRewards: {},
        fetchRewardsPending: false,
        fetchRewardsError: '',
        discountStore: {},
        discountVoucher: {},
        crm: {},
        cashbackApplied: false,
        discountVoucherError: false,
        discountStoreError: false,
        rewardsBanner: [],
      };
    default:
      return state;
  }
}
