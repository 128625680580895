import * as actionTypes from '../../actionTypes';

const initialState = {
  loadingSTK: false,
  loadingOrder: false,
  tableOccupied: false,
  order: {},
  fcOrder: {},
  paymentMode: '',
  paymentPending: false,
  ratingsCompleted: [],
  coupons: [],
  fetchPayment: false,
  loadingCoupon: false,
  orderAddress: {},
  storeLoyalty: [],
  userLoyalty: {},
  enigmaPopup: false,
  sharableVoucher: {},
};

export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING_SEND_TO_KITCHEN:
      return {
        ...state,
        loadingSTK: action.payload,
      };
    case actionTypes.INIT_STORE_LOYALTY:
      return {
        ...state,
        storeLoyalty: action.payload,
      };
    case actionTypes.INIT_USER_LOYALTY:
      return {
        ...state,
        userLoyalty: action.payload,
      };
    case actionTypes.LOADING_ORDER:
      return {
        ...state,
        loadingOrder: action.payload,
      };
    case 'SET_ENIGMA_POPUP':
      return {
        ...state,
        enigmaPopup: action.payload,
      };
    case actionTypes.SET_TABLE_ALREADY_OCCUPIED:
      return {
        ...state,
        tableOccupied: action.payload,
      };
    case actionTypes.SET_ORDER_DETAILS:
      return {
        ...state,
        order: action.payload,
      };
    case actionTypes.UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMode: action.payload,
      };
    case actionTypes.SET_FC_ORDER_DETAILS:
      return {
        ...state,
        fcOrder: action.payload,
      };
    case actionTypes.PAYMENT_PENDING:
      return {
        ...state,
        paymentPending: action.payload,
      };
    case actionTypes.SAVE_COUPONS:
      let tempCoupon = [];
      if (action.payload.rewards && action.payload.rewards.length > 0) {
        action.payload.rewards.forEach((reward) => {
          let rew = {...reward.voucher};
          rew['discount_type'] = 'reward';
          rew['_id'] = reward._id;
          tempCoupon.push(rew);
        });
      }
      if (action.payload.vouchers && action.payload.vouchers.length > 0) {
        action.payload.vouchers.forEach((voucher) => {
          let vou = {...voucher};
          vou['discount_type'] = 'voucher';
          tempCoupon.push(vou);
        });
      }
      return {
        ...state,
        coupons: tempCoupon,
      };
    case actionTypes.LOADING_COUPON:
      return {
        ...state,
        loadingCoupon: action.payload,
      };
    case actionTypes.ADD_RATINGS_COMPLETED:
      let addRatings = [...state.ratingsCompleted];
      addRatings.push(action.payload);
      return {
        ...state,
        ratingsCompleted: addRatings,
      };
    case actionTypes.SELECTED_ORDER_ADDRESS:
      return {
        ...state,
        orderAddress: action.payload,
      };
    case actionTypes.FETCH_PAYMENT_STATUS:
      return {
        ...state,
        fetchPayment: action.payload,
      };
    case actionTypes.SET_SHARABLE_VOUCHER:
      return {
        ...state,
        sharableVoucher: action.payload,
      };
    case actionTypes.RESET_GROUP:
      return {
        loadingSTK: false,
        loadingOrder: false,
        tableOccupied: false,
        order: {},
        fcOrder: {},
        paymentMode: '',
        paymentPending: false,
        ratingsCompleted: [],
        coupons: [],
        loadingCoupon: false,
        fetchPayment: false,
        orderAddress: {},
        storeLoyalty: [],
        userLoyalty: {},
        enigmaPopup: false,
        sharableVoucher: {},
      };
    default:
      return state;
  }
}
