/** misc actiontypes */
export const SET_SCAN_PENDING = 'SET_SCAN_PENDING';
export const SET_SCAN_SUCCESS = 'SET_SCAN_SUCCESS';
export const SET_SCAN_ERROR = 'SET_SCAN_ERROR';
export const SET_SCAN_DATA = 'SET_SCAN_DATA';
export const SET_SCANNED_store_id = 'SET_SCANNED_store_id';
export const SET_ORDER_MODE = 'SET_ORDER_MODE';
export const SET_SNACK_MESSAGE = 'SET_SNACK_MESSAGE';
export const FOOD_COURT_LOADING = 'FOOD_COURT_LOADING';
export const SET_FOOD_COURT_DATA = 'SET_FOOD_COURT_DATA';
export const SET_LANGUAGE_MODAL = 'SET_LANGUAGE_MODAL';
export const SET_UNSUPPORTED_LANG = 'SET_UNSUPPORTED_LANG';
export const SET_SERVICE_MODAL = 'SET_SERVICE_MODAL';
export const INIT_SUBSCRIPTION_PAYMENT = 'INIT_SUBSCRIPTION_PAYMENT';
export const SET_VEG = 'SET_VEG';
export const QUEUE_STATUS = 'QUEUE_STATUS';
export const INIT_JUKEBOX = 'INIT_JUKEBOX';
export const SET_FEEDBACK_STATUS = 'SET_FEEDBACK_STATUS';
export const LOADING_RATINGS_MISC = 'LOADING_RATINGS_MISC';
export const INIT_RATINGS_MISC = 'INIT_RATINGS_MISC';
export const SAVE_TRACK_ORDER = 'SAVE_TRACK_ORDER';
export const RESET_MISC = 'RESET_MISC';
