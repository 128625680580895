/** send actiontypes */
export const LOADING_SEND_TO_KITCHEN = 'LOADING_SEND_TO_KITCHEN';
export const LOADING_ORDER = 'LOADING_ORDER';
export const INIT_STORE_LOYALTY = 'INIT_STORE_LOYALTY';
export const INIT_USER_LOYALTY = 'INIT_USER_LOYALTY';
export const SET_TABLE_ALREADY_OCCUPIED = 'SET_TABLE_ALREADY_OCCUPIED';
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS';
export const SET_FC_ORDER_DETAILS = 'SET_FC_ORDER_DETAILS';
export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';
export const PAYMENT_PENDING = 'PAYMENT_PENDING';
export const ADD_RATINGS_COMPLETED = 'ADD_RATINGS_COMPLETED';
export const FETCH_PAYMENT_STATUS = 'FETCH_PAYMENT_STATUS';
export const LOADING_COUPON = 'LOADING_COUPON';
export const SET_SHARABLE_VOUCHER = 'SET_SHARABLE_VOUCHER';
export const SAVE_COUPONS = 'SAVE_COUPONS';
export const RESET_GROUP = 'RESET_GROUP';
