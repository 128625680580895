import React, {createRef, useEffect, useState} from 'react';
import {useStyles} from './styles';
import Grid from '@material-ui/core/Grid';

function DashedInput({
  length,
  value,
  setValue,
  OTPFocus,
  mode,
  handleClickSubmit,
}) {
  const classes = useStyles();
  const CODE_LENGTH = new Array(length).fill(0);
  const inputBlinker = length === 6 ? 16 : 18;
  const [focused, setFocused] = useState(false);
  const input = createRef();
  const values = value.split('');
  const selectedIndex =
    values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;

  const hideInput = !(values.length < CODE_LENGTH.length);
  useEffect(() => {
    OTPFocus && input.current.focus();
  });
  function handleClick() {
    input.current.focus();
  }

  function handleFocus() {
    setFocused(true);
  }

  // function handleBlur() {
  //   setFocused(false);
  // }

  function handleChange(e) {
    const newValue = e.target.value;
    setValue((prev) => {
      if (prev.length >= CODE_LENGTH.length) return prev;
      return (prev + newValue).slice(0, CODE_LENGTH.length);
    });
  }

  function handleKeyUp(e) {
    // e.preventDefault();
    if (e.key === 'Backspace') {
      setValue((prev) => {
        return prev.slice(0, prev.length - 1);
      });
    }

    if (e.key === 'Enter') {
      handleClickSubmit();
    }
  }

  return (
    <Grid
      spacing={0}
      container
      direction="column"
      justify="center"
      alignItems="stretch"
      className={classes.root}
    >
      <Grid item xs={12} className={classes.wrap} onClick={handleClick}>
        <input
          value=""
          type={'number'}
          ref={input}
          onChange={mode !== 'read' && handleChange}
          onKeyUp={mode !== 'read' && handleKeyUp}
          onFocus={mode !== 'read' && handleFocus}
          // onBlur={mode !== 'read' && handleBlur}
          className={classes.input}
          style={{
            height: '2em',
            width: '12vw',
            top: 0,
            bottom: 0,
            left: `${selectedIndex * inputBlinker}vw`,
            opacity: hideInput ? 0 : 1,
          }}
        />
        {CODE_LENGTH.map((v, index) => {
          const selected = values.length === index;
          const filled =
            values.length === CODE_LENGTH.length &&
            index === CODE_LENGTH.length - 1;

          return (
            <div className={classes.display} key={index}>
              {values[index]}
              {(selected || filled) && focused && (
                <div
                  className={`${classes.shadows} ${
                    focused ? classes.focused : ''
                  }`}
                />
              )}
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default DashedInput;
