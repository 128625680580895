import * as actionTypes from '../../actionTypes';

const initialState = {
  nameSearch: false,
  nameSearchData: {
    result: [],
    __metadata: {},
  },
};

export default function storeReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_NAME_SEARCH:
      return {
        ...state,
        nameSearch: action.payload,
      };
    case actionTypes.SET_NAME_SEARCH_DATA_PAGINATION: {
      const temp = {...state.nameSearchData};
      if (temp.result) {
        action.payload.result &&
          action.payload.result.length > 0 &&
          action.payload.result.forEach((res) => {
            temp.result.push(res);
          });
      }
      temp['__metadata'] = action.payload.__metadata;
      return {
        ...state,
        nameSearchData: temp,
      };
    }
    case actionTypes.SET_NAME_SEARCH_DATA:
      return {
        ...state,
        nameSearchData: action.payload,
      };
    default:
      return state;
  }
}
