import React from 'react';
import Lottie from 'react-lottie';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {useSelector} from 'react-redux';
import * as animationDataLoading from '../../assets/animation/loading';
// import * as animationDataWalkaway from '../../assets/animation/walkaway';
import * as animationDataLoadingCoupon from '../../assets/animation/couponLoading';
// import {setPaymentFetch} from '../../services/actions';
// import Button from '@material-ui/core/Button';

function LoadingModal() {
  const loadingSTK = useSelector((state) => state.group.loadingSTK);
  const loadingOrder = useSelector((state) => state.group.loadingOrder);
  const loadingCoupon = useSelector((state) => state.group.loadingCoupon);
  const loading = useSelector(
    (state) => state.profile && state.profile.shareableVoucherLoading
  );
  // const fetchPayment = useSelector((state) => state.group.fetchPayment);
  // const paymentPending = useSelector((state) => state.group.paymentPending);
  const defaultLoading = {
    loop: true,
    autoplay: true,
    animationData: animationDataLoading.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const defaultLoadingCoupon = {
    loop: true,
    autoplay: true,
    animationData: animationDataLoadingCoupon.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  // const defaultPaymentPending = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationDataWalkaway.default,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };
  return (
    <>
      <Dialog
        style={{padding: '0 !important', zIndex: 1500}}
        maxWidth={'xs'}
        open={loadingSTK || loadingOrder || loading}
      >
        <DialogContent
          style={{padding: 16, paddingTop: 0, textAlign: 'center'}}
        >
          <Lottie
            options={defaultLoading}
            height={150}
            width={150}
            isClickToPauseDisabled={true}
          />
          <div
            style={{
              fontSize: 12,
              fontWeight: 500,
              marginTop: -20,
            }}
          >
            TOASTING &apos;EM BUNS
          </div>
        </DialogContent>
      </Dialog>
      {/* <Dialog*/}
      {/*  style={{padding: '0 !important', zIndex: 1500}}*/}
      {/*  maxWidth={'xs'}*/}
      {/*  open={paymentPending}*/}
      {/* >*/}
      {/*  <DialogContent style={{padding: 16, paddingTop: 0}}>*/}
      {/*    <Lottie*/}
      {/*      options={defaultPaymentPending}*/}
      {/*      height={150}*/}
      {/*      width={150}*/}
      {/*      isClickToPauseDisabled={true}*/}
      {/*    />*/}
      {/*    <div*/}
      {/*      style={{*/}
      {/*        textAlign: 'center',*/}
      {/*        fontSize: 12,*/}
      {/*        fontWeight: 500,*/}
      {/*        marginTop: -10,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      PAYMENT IN PROGRESS*/}
      {/*    </div>*/}
      {/*  </DialogContent>*/}
      {/* </Dialog>*/}
      <Dialog
        style={{padding: '0 !important', zIndex: 1500}}
        maxWidth={'xs'}
        open={loadingCoupon}
      >
        <DialogContent style={{padding: 16}}>
          <Lottie
            options={defaultLoadingCoupon}
            height={150}
            width={150}
            isClickToPauseDisabled={true}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LoadingModal;
