/** Auth actiontypes */
export const COUNTRY_CODE = 'COUNTRY_CODE';
export const MOBILE_NUMBER = 'MOBILE_NUMBER';
export const OTP_SENT = 'OTP_SENT';
export const WHATSAPP_OTP_SENT = 'WHATSAPP_OTP_SENT';
export const LOGIN_API_LOADING = 'LOGIN_API_LOADING';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const CLEAR_USER = 'CLEAR_USER';
export const UPDATE_LOGIN_DATA = 'UPDATE_LOGIN_DATA';
export const UPDATE_USER_ORDER = 'UPDATE_USER_ORDER';
export const REMOVE_USER_ORDER = 'REMOVE_USER_ORDER';
export const SET_USER = 'SET_USER';
export const TRUECALLER_REQ_ID = 'TRUECALLER_REQ_ID';
export const LOADING_USER = 'LOADING_USER';
export const UPDATE_USER_CREDITS = 'UPDATE_USER_CREDITS';
