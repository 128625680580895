import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dialogTitleGroup: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  dialogContentGroup: {
    fontSize: 13,
  },
  drawerTopCorners: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
}));
