import axios from 'axios';
import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';

export const nameSearchLoading = (value) => ({
  type: actionTypes.SET_NAME_SEARCH,
  payload: value,
});

export const setNameSearchData = (stores) => ({
  type: actionTypes.SET_NAME_SEARCH_DATA,
  payload: stores,
});

export const getSearchStoreName = (value, platform) => {
  return (dispatch) => {
    dispatch(nameSearchLoading(true));
    axios
      .get(
        `${API_URL}/stores/vname?delivery=true&platforms=${platform}&visible_name=${value}`
      )
      .then((res) => {
        dispatch(setNameSearchData(res.data));
        dispatch(nameSearchLoading(false));
      })
      .catch(() => {
        dispatch(
          setNameSearchData({
            result: [],
            __metadata: {},
          })
        );
        dispatch(nameSearchLoading(false));
      });
  };
};

export const getSearchStoreNamePagination = (url) => {
  return (dispatch) => {
    axios
      .get(url)
      .then((res) =>
        dispatch({
          type: actionTypes.SET_NAME_SEARCH_DATA_PAGINATION,
          payload: res.data,
        })
      )
      .catch((err) => console.log(err));
  };
};
