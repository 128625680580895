export const GET_REWARDS_PENDING = 'GET_REWARDS_PENDING';
export const GET_REWARDS_SUCCESS = 'GET_REWARDS_SUCCESS';
export const GET_REWARDS_FAILED = 'GET_REWARDS_FAILED';
export const GET_USER_REWARDS_PENDING = 'GET_USER_REWARDS_PENDING';
export const GET_USER_REWARDS_SUCCESS = 'GET_USER_REWARDS_SUCCESS';
export const GET_USER_REWARDS_FAILED = 'GET_USER_REWARDS_FAILED';
export const GET_USER_REWARDS_SUCCESS_PAGINATION =
  'GET_USER_REWARDS_SUCCESS_PAGINATION';
export const GET_DISCOUNT_STORE = 'GET_DISCOUNT_STORE';
export const GET_DISCOUNT_VOUCHER = 'GET_DISCOUNT_VOUCHER';
export const GET_BANNER_VOUCHER = 'GET_BANNER_VOUCHER';
export const CASHBACK_APPLIED = 'CASHBACK_APPLIED';
export const GET_CRM_DETAILS = 'GET_CRM_DETAILS';
export const DISCOUNT_VOUCHER_ERROR = 'DISCOUNT_VOUCHER_ERROR';
export const DISCOUNT_STORE_ERROR = 'DISCOUNT_STORE_ERROR';
