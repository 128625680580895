/** cart actiontypes */
export const ADD_TO_CART = 'ADD_TO_CART';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const ADDON_SELECTION = 'ADDON_SELECTION';
export const IMAGE_ITEM_SELECTION = 'IMAGE_ITEM_SELECTION';
export const ADD_TO_CART_ADDON = 'ADD_TO_CART_ADDON';
export const DELETE_FROM_CART_ADDON = 'DELETE_FROM_CART_ADDON';
export const ADD_TO_CART_NOTE = 'ADD_TO_CART_NOTE';
export const REMOVE_TO_CART_NOTE = 'REMOVE_TO_CART_NOTE';
export const ADD_LOYALTY_CART = 'ADD_LOYALTY_CART';
export const REMOVE_LOYALTY_CART = 'REMOVE_LOYALTY_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const CLEAR_LOYALTY_CART = 'CLEAR_LOYALTY_CART';
