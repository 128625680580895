import {http} from '../../http';
import {API_URL, RZP_KEY, UI_PATH} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {push, goBack, replace} from 'connected-react-router';
import {toast} from 'react-toastify';
import {
  finishDine,
  getCategory,
  getItems,
  getRestData,
  getHotItems,
  ratingsPageVisibility,
  getItemCombos,
} from '../sourceActions';
import {setScanData, setServiceModal} from '../miscActions';
import {resetLoyaltyCart} from '../cartActions';

export const setPaymentFetch = (status) => ({
  type: actionTypes.FETCH_PAYMENT_STATUS,
  payload: status,
});

export const getStoreLoyalty = (storeID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .get(`${API_URL}/loyalty/${storeID}`)
      .then((res) => {
        dispatch(resetLoyaltyCart());
        dispatch({type: actionTypes.INIT_STORE_LOYALTY, payload: res.data});
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const getUserLoyalty = (storeID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .get(`${API_URL}/crm/${storeID}/loyalty`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_USER_LOYALTY, payload: res.data});
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const orderCreation = (cart, type, restID, tableID, name, billPath) => {
  return (dispatch, getState) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    const data = {};
    const source = getState().misc.scanData && getState().misc.scanData.source;
    data['platform'] = 'bolt';
    data['items'] = cart;
    data['__type'] = type;
    data['store_id'] = restID;
    if (source === 'tingo') {
      data['platform'] = 'tingo';
    }
    if (source === 'broeat') {
      data['platform'] = 'broeat';
    }
    if (tableID) {
      data['table_id'] = tableID;
    }
    if (name) {
      data['user_name'] = name;
    }
    http
      .post(`${API_URL}/orders`, data)
      .then(async (res) => {
        await dispatch(updateOrder(res.data));
        if (type === 'dinein') {
          await dispatch({type: actionTypes.CLEAR_CART});
          await dispatch(replace('/home/social-impression'));
          // if (
          //   res.data &&
          //   res.data.payment &&
          //   res.data.payment.available_modes &&
          //   res.data.payment.available_modes.includes('online')
          // ) {
          //   await dispatch(setFeedBackStatus(true));
          // }
          // if (process.env.REACT_APP_ENV !== 'development') {
          //   await dispatch(setFeedBackStatus(true));
          // }
          toast.success(
            'Your order has been placed, you will soon get more detail'
          );
        }
        if (billPath) {
          await dispatch(push(billPath));
        }
        await dispatch({
          type: actionTypes.LOADING_ORDER,
          payload: false,
        });
      })
      .catch(async (err) => {
        if (err && err.response && err.response.data) {
          if (err.response.data.item_id) {
            dispatch({
              type: 'ITEM_NOT_AVAILABLE',
              payload: err.response.data.item_id,
            });
          }
          if (err.response.data.status === 422) {
            await dispatch(push('/home'));
            await dispatch({
              type: actionTypes.SET_TABLE_ALREADY_OCCUPIED,
              payload: true,
            });
          }
          toast.error(err.response.data.message);
        }
        await dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const orderCreationDelivery = (
  cart,
  storeID,
  addressID,
  itemNA,
  billPath,
  schedule
) => {
  return (dispatch, getState) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    const data = {};
    const source = getState().misc.scanData && getState().misc.scanData.source;
    data['platform'] = 'bolt';
    data['items'] = cart;
    data['__type'] = 'delivery';
    data['store_id'] = storeID;
    data['store_recommendations'] = itemNA;
    if (source === 'tingo') {
      data['platform'] = 'tingo';
    }
    if (source === 'broeat') {
      data['platform'] = 'broeat';
    }
    if (addressID) {
      data['user_address_id'] = addressID;
    }
    if (schedule) {
      data['scheduled_delivery_time'] = schedule;
    }
    http
      .post(`${API_URL}/orders`, data)
      .then(async (res) => {
        await dispatch(updateOrder(res.data));
        if (billPath) {
          await dispatch(push(billPath));
        }
        await dispatch({
          type: actionTypes.LOADING_ORDER,
          payload: false,
        });
      })
      .catch(async (err) => {
        if (err && err.response && err.response.data) {
          if (err.response.data.item_id) {
            dispatch({
              type: 'ITEM_NOT_AVAILABLE',
              payload: err.response.data.item_id,
            });
          }
          toast.error(err.response.data.message);
        }
        await dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const fcOrderCreation = (cart, type, groupID, name, billPath) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    const data = {};
    data['items'] = cart;
    data['store_group_id'] = groupID;
    data['user_name'] = name;
    http
      .post(`${API_URL}/order_group`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_FC_ORDER_DETAILS,
          payload: res.data,
        });
        if (billPath) {
          await dispatch(push(billPath));
        }
        await dispatch({
          type: actionTypes.LOADING_ORDER,
          payload: false,
        });
      })
      .catch(async (err) => {
        if (err && err.response && err.response.data) {
          if (err.response.data.item_id) {
            dispatch({
              type: 'ITEM_NOT_AVAILABLE',
              payload: err.response.data.item_id,
            });
          }
          toast.error(err.response.data.message);
          // await dispatch(
          //   setSnackMessage(
          //     err.response.data.message,
          //     'error',
          //     'top',
          //     'center',
          //     1500
          //   )
          // );
        }
        await dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const addUserEnigma = (enigmaCode, fCodeVerification) => {
  return (dispatch, getState) => {
    const state = getState();
    const scanData = state.misc.scanData;
    let data = {
      store_id: scanData.store_id,
      table_id: scanData.table_id,
      enigma: enigmaCode,
    };
    fCodeVerification('Pending');
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .post(`${API_URL}/orders/user`, data)
      .then(async (res) => {
        fCodeVerification('pass');
        await dispatch(updateOrder(res.data));
        await dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch(() => {
        fCodeVerification('Failed');
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const addToCurrentOrder = (orderID, items, billPath) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    const data = {};
    data['items'] = items;
    http
      .put(`${API_URL}/orders/${orderID}`, data)
      .then(async (res) => {
        await dispatch(updateOrder(res.data));
        if (res.data.__type === 'dinein') {
          await dispatch({type: actionTypes.CLEAR_CART});
          await dispatch(goBack());
          toast.success(
            'Your order has been placed, you will soon get more detail'
          );
          // await dispatch(
          //   setSnackMessage(
          //     'Your order has been placed, you will soon get more detail',
          //     'success',
          //     'bottom',
          //     'center',
          //     3000
          //   )
          // );
        }
        if (billPath) {
          await dispatch(push(billPath));
        }
        await dispatch({
          type: actionTypes.LOADING_ORDER,
          payload: false,
        });
      })
      .catch(async (err) => {
        if (err && err.response && err.response.data) {
          if (err.response.data.item_id) {
            dispatch({
              type: 'ITEM_NOT_AVAILABLE',
              payload: err.response.data.item_id,
            });
          }
          toast.error(err.response.data.message);
          // await dispatch(
          //   setSnackMessage(
          //     err.response.data.message,
          //     'error',
          //     'top',
          //     'center',
          //     1500
          //   )
          // );
        }
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const addTip = (orderID, value) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: true});
    http
      .put(`${API_URL}/orders/${orderID}`, {tip: value})
      .then(async (res) => {
        await dispatch(updateOrder(res.data));
        await dispatch({
          type: actionTypes.LOADING_SEND_TO_KITCHEN,
          payload: false,
        });
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: false});
      });
  };
};

export const getService = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    const scanData = state.misc.scanData;
    let req = {
      services: [...data],
      table_id: scanData.table_id,
    };
    dispatch(setServiceModal(false));
    http
      .post(`${API_URL}/services/${scanData.store_id}/send`, req)
      .then(() => {
        toast.success('Waiter will reach your table soon');
        // dispatch(
        //   setSnackMessage(
        //     'Waiter will reach your table soon',
        //     'success',
        //     'bottom',
        //     'center',
        //     2000
        //   )
        // );
      })
      .catch(() => {});
  };
};

export const updateOrder = (order) => ({
  type: actionTypes.SET_ORDER_DETAILS,
  payload: order,
});

export const updateCurrentOrder = (orderID) => {
  return (dispatch) => {
    http
      .get(`${API_URL}/orders/${orderID}`)
      .then(async (res) => {
        await dispatch(updateOrder(res.data));
        await dispatch({type: actionTypes.LOADING_ORDER, payload: false});
        if (res.data.__type === 'dinein') {
          if (
            (res.data.curr_state === 'payment-initalized' &&
              res.data.payment &&
              res.data.payment.mode !== 'online') ||
            res.data.curr_state === 'payment-captured' ||
            res.data.curr_state === 'settled' ||
            res.data.paid
          ) {
            if (
              !(
                window.location.pathname === '/ratings' ||
                window.location.pathname === '/' ||
                window.location.pathname === '/credits'
              )
            ) {
              await dispatch(setPaymentFetch(false));
              await dispatch(paymentProgress(true));
              await dispatch(ratingsPageVisibility(true));
              await dispatch(push('/ratings'));
            }
          }
        } else if (
          (res.data.curr_state === 'payment-initalized' &&
            res.data.payment.mode &&
            res.data.payment.mode !== 'online') ||
          res.data.curr_state === 'payment-captured' ||
          res.data.paid
        ) {
          await dispatch(replace('/profile/transactions'));
          await dispatch(updateOrder({}));
          await dispatch(finishDine());
        }
      })
      .catch(async (err) => {
        if (err && err.response && err.response.data) {
          toast.error(err.response.data.message);
        }
        await dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const payCashCard = (orderID, mode) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: true});
    http
      .post(`${API_URL}/orders/${orderID}/pay`, {
        payment_mode: mode,
      })
      .then(async () => {
        await dispatch(setPaymentFetch(false));
        await dispatch(paymentProgress(true));
        await dispatch(ratingsPageVisibility(true));
        await dispatch(push('/ratings'));
        await dispatch({
          type: actionTypes.LOADING_SEND_TO_KITCHEN,
          payload: false,
        });
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: false});
      });
  };
};

export const payCod = (orderID, mode) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: true});
    http
      .post(`${API_URL}/orders/${orderID}/pay`, {
        payment_mode: mode,
        platform: 'tingo',
      })
      .then(async (res) => {
        await dispatch(updateOrder(res.data));
        await dispatch({
          type: actionTypes.LOADING_SEND_TO_KITCHEN,
          payload: false,
        });
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          toast.error(err.response.data.message);
        }
        dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: false});
      });
  };
};

export const payOnline = (orderID, data, cb, card) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: true});
    http
      .post(`${API_URL}/orders/${orderID}/pay`, data)
      .then(async (res) => {
        await dispatch(updateOrder(res.data));
        await dispatch({
          type: actionTypes.LOADING_SEND_TO_KITCHEN,
          payload: false,
        });
        if (data.online_mode === 'card' && cb && !card) {
          await cb(res.data.payment.online);
        }
        if (data.online_mode === 'card' && cb && card) {
          await cb(res.data.payment.online, card);
        }
        if (
          data.online_mode === 'upi' &&
          res.data.payment &&
          res.data.payment.online
        ) {
          if (res.data.payment.online.aggregator === 'bharatpe') {
            if (data.payerVpa) {
              dispatch(setPaymentFetch(true));
            } else if (res.data.payment.online.order_id) {
              const link = document.createElement('a');
              link.href = res.data.payment.online.order_id;
              document.body.appendChild(link);
              link.click();
            }
          }
          if (res.data.payment.online.aggregator === 'razorpay' && cb) {
            await cb(res.data.payment.online, data.payerVpa);
          }
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          toast.error(err.response.data.message);
        }
        dispatch({type: actionTypes.LOADING_SEND_TO_KITCHEN, payload: false});
      });
  };
};

export const razorPayUPICheckout = (rzp, type, payerVPA) => {
  return (dispatch, getState) => {
    const razorpay = new window.Razorpay({
      key: RZP_KEY,
      callback_url:
        type === 'dinein'
          ? `${API_URL}/payments/razorpay/checkout?url=${UI_PATH}/ratings`
          : `${API_URL}/payments/razorpay/checkout?url=${UI_PATH}/profile/transactions`,
      redirect: false,
    });
    const user = getState().auth.userData;
    const data = {
      order_id: rzp.order_id,
      amount: rzp.amount,
      contact: user.phone,
      method: 'upi',
    };
    if (payerVPA) {
      data['vpa'] = payerVPA;
    }
    if (razorpay) {
      razorpay.createPayment(data);
      razorpay.on('payment.success', async function (res) {
        await dispatch({type: actionTypes.CLEAR_CART});
        if (type === 'dinein') {
          await dispatch(replace('/ratings'));
        } else {
          await dispatch(replace('/profile/transactions'));
          await dispatch(updateOrder({}));
          await dispatch(finishDine());
        }
      });
      razorpay.on('payment.error', function (err) {
        toast.error(err.error.description);
      });
    }
  };
};

export const razorPayCardCheckout = (rzp, card, type) => {
  return (dispatch, getState) => {
    const razorpay = new window.Razorpay({
      key: RZP_KEY,
      callback_url:
        type === 'dinein'
          ? `${API_URL}/payments/razorpay/checkout?url=${UI_PATH}/ratings`
          : `${API_URL}/payments/razorpay/checkout?url=${UI_PATH}/profile/transactions`,
      redirect: false,
    });
    const user = getState().auth.userData;
    const data = {
      order_id: rzp.order_id,
      amount: rzp.amount,
      contact: user.phone,
      method: 'card',
      'card[name]': card.name,
      'card[number]': card.number,
      'card[cvv]': card.cvv,
      'card[expiry_month]': card.validity.split('/')[0],
      'card[expiry_year]': card.validity.split('/')[1],
    };
    if (razorpay) {
      razorpay.createPayment(data);
      razorpay.on('payment.success', async function (res) {
        await dispatch({type: actionTypes.CLEAR_CART});
        if (type === 'dinein') {
          await dispatch(replace('/ratings'));
        } else {
          await dispatch(replace('/profile/transactions'));
          await dispatch(updateOrder({}));
          await dispatch(finishDine());
        }
      });
      razorpay.on('payment.error', function (err) {
        toast.error(err.error.description);
      });
    }
  };
};

export const setOrderAddress = (address) => ({
  type: actionTypes.SELECTED_ORDER_ADDRESS,
  payload: address,
});

export const paymentProgress = (status) => ({
  type: actionTypes.PAYMENT_PENDING,
  payload: status,
});

export const updatePaymentMethod = (method) => ({
  type: actionTypes.UPDATE_PAYMENT_METHOD,
  payload: method,
});

export const resetGroup = () => ({
  type: actionTypes.RESET_GROUP,
});

export const getCoupon = (orderID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_COUPON, payload: true});
    http
      .get(`${API_URL}/orders/${orderID}/vouchers`)
      .then((res) => {
        dispatch({type: actionTypes.SAVE_COUPONS, payload: res.data});
        dispatch({type: actionTypes.LOADING_COUPON, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_COUPON, payload: false});
      });
  };
};

export const applyCoupon = (orderID, voucherID, type) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_COUPON, payload: true});
    const data = {
      ref_id: voucherID,
      discount_type: type,
    };
    http
      .put(`${API_URL}/orders/${orderID}`, {
        discount: data,
      })
      .then((res) => {
        dispatch(updateOrder(res.data));
        dispatch(goBack());
        dispatch({type: actionTypes.LOADING_COUPON, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_COUPON, payload: false});
      });
  };
};

export const applyLoyalty = (orderID, data) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_COUPON, payload: true});
    http
      .post(`${API_URL}/orders/${orderID}/loyalty`, data)
      .then(() => {
        // dispatch(updateOrder(res.data));
        dispatch(goBack());
        dispatch(resetLoyaltyCart());
        dispatch({type: actionTypes.LOADING_COUPON, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_COUPON, payload: false});
      });
  };
};

export const ratingsCompletedFor = (id) => ({
  type: actionTypes.ADD_RATINGS_COMPLETED,
  payload: id,
});

export const initEbill = (orderID, errorCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .get(`${API_URL}/orders/${orderID}`)
      .then(async (res) => {
        if (res.data && res.data.store_id && res.data.store_id._id) {
          await dispatch(getRestData(res.data.store_id._id));
          await dispatch(getItems(res.data.store_id._id, res.data.__type));
          await dispatch(getItemCombos(res.data.store_id._id));
          await dispatch(getCategory(res.data.store_id._id, res.data.__type));
          await dispatch(getHotItems(res.data.store_id._id));
          await dispatch(updateOrder(res.data));
          await dispatch(
            setScanData({
              type: res.data.__type,
              store_group_id: '',
              store_id: res.data.store_id._id,
              table_id: '',
              source: 'wtf',
              scheduleDelivery: null,
            })
          );
        }
        await dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch(() => {
        errorCB();
        toast.error('Error in getting bill, logout and try again.');
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const getSharableVouchers = (orderID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .get(`${API_URL}/orders/${orderID}/shared/voucher`)
      .then((res) => {
        dispatch({type: actionTypes.SET_SHARABLE_VOUCHER, payload: res.data});
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};
