import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {fetchUserDetails} from './actions/authActions';

function UserGroupRestore() {
  const dispatch = useDispatch();
  const userID = useSelector(
    (state) =>
      state.auth.userData &&
      state.auth.userData.user &&
      state.auth.userData.user._id
  );
  useEffect(() => {
    if (userID) {
      dispatch(fetchUserDetails());
    }
  }, [userID, dispatch]);
  return <></>;
}

export default UserGroupRestore;
