import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

function ScannedRouterHoc({component: Component, ...rest}) {
  const scannedData = useSelector((state) => state.misc.scanData);
  return (
    <Route
      {...rest}
      render={(prop) =>
        scannedData.store_id && scannedData.store_id !== '' ? (
          <Component {...prop} />
        ) : (
          <Redirect to={`/`} />
        )
      }
    />
  );
}

export default ScannedRouterHoc;
