import * as actionTypes from '../../actionTypes';

const initialState = {
  countryCode: '+91',
  mobileNumber: '',
  otpSent: false,
  wpOtpSent: false,
  apiLoading: false,
  loginErr: false,
  userData: {},
  truecallerReqID: '',
  loadingUser: false,
  credits: {},
};

export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.payload,
      };
    case actionTypes.MOBILE_NUMBER:
      return {
        ...state,
        mobileNumber: action.payload,
      };
    case actionTypes.OTP_SENT:
      return {
        ...state,
        otpSent: action.payload,
      };
    case actionTypes.WHATSAPP_OTP_SENT:
      return {
        ...state,
        wpOtpSent: action.payload,
      };
    case actionTypes.LOGIN_API_LOADING:
      return {
        ...state,
        apiLoading: action.payload,
      };
    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        loginErr: action.payload,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        mobileNumber: '',
        otpSent: false,
        wpOtpSent: false,
        userData: action.payload,
      };
    case actionTypes.SET_USER:
      return {
        ...state,
        userData: {
          ...state.userData,
          user: action.payload,
        },
      };
    case actionTypes.UPDATE_USER_ORDER:
      const updateUser = {...state.userData};
      updateUser['current_order'] = action.payload;
      return {
        ...state,
        userData: updateUser,
      };
    case actionTypes.REMOVE_USER_ORDER:
      const removeUser = {...state.userData};
      delete removeUser['current_order'];
      return {
        ...state,
        userData: removeUser,
      };
    case actionTypes.UPDATE_LOGIN_DATA:
      const update = {...state.userData};
      if (update.user) {
        update['user'] = action.payload;
      }
      return {
        ...state,
        userData: update,
      };
    case actionTypes.LOADING_USER:
      return {
        ...state,
        loadingUser: action.payload,
      };
    case actionTypes.TRUECALLER_REQ_ID:
      return {
        ...state,
        truecallerReqID: action.payload,
      };
    case actionTypes.UPDATE_USER_CREDITS:
      return {
        ...state,
        credits: action.payload,
      };
    case actionTypes.CLEAR_USER:
      localStorage.removeItem('auth_token');
      return {
        ...state,
        mobileNumber: '',
        otpSent: false,
        wpOtpSent: false,
        apiLoading: false,
        loginErr: false,
        userData: {},
        loadingUser: false,
        truecallerReqID: '',
        credits: {},
      };
    default:
      return state;
  }
}
