import React, {lazy, useEffect} from 'react';
import {Switch, Route, useHistory} from 'react-router';
import {ThemeProvider} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import './App.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactGA from 'react-ga';

import suspenseHoc from './hoc/suspenseHoc';
import PrivateRouteHoc from './hoc/PrivateRouteHoc';
import ScannedRouterHoc from './hoc/ScannedRouteHoc';
import ScannedRouteHomeHoc from './hoc/ScannedRouteHomeHoc';
// import LocationRouteHoc from './hoc/LocationRouteHoc';
import {lightTheme, darkTheme} from './utils/themes/theme';
import footerHoc from './hoc/footerHoc';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import LoadingModal from './modules/LoadingModal';
import Ably from './services/Ably';
import UserGroupRestore from './services/UserGroupRestore';
import EnigmaModal from './modules/EnigmaModal';
import {GA_KEY} from './utils/constants';
import {setSnackMessage} from './services/actions';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaymentRouterHoc from './hoc/PaymentRouteHoc';

const LandingPage = lazy(() => import('./pages/LandingPage'));
const QRScanPage = lazy(() => import('./pages/QRScanPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const CartPage = lazy(() => import('./pages/CartPage'));
const PaymentPage = lazy(() => import('./pages/PaymentPage'));
const FoodCourtPage = lazy(() => import('./pages/FoodCourtPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const EditProfilePage = lazy(() =>
  import('./pages/ProfilePage/pages/EditProfilePage')
);
const TransactionsPage = lazy(() =>
  import('./pages/ProfilePage/pages/TransactionsPage')
);
const RewardsPage = lazy(() => import('./pages/ProfilePage/pages/RewardsPage'));
const SocialPage = lazy(() => import('./pages/SocialPage'));
const EnigmaPage = lazy(() => import('./pages/EnigmaPage'));
const Game2048 = lazy(() => import('./games/Game2048'));
const RewardCreditsPage = lazy(() => import('./pages/RewardCreditsPage'));
const RewardSelectionPage = lazy(() => import('./pages/RewardsPage'));
// const LoyaltyPage = lazy(() => import('./pages/ProfilePage/pages/LoyaltyPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const RatingsPage = lazy(() => import('./pages/RatingsPage'));
const EbillPage = lazy(() => import('./pages/EbillPage'));
const ApplyCouponPage = lazy(() => import('./pages/ApplyCouponPage'));
const BroeatStorePage = lazy(() => import('./pages/BroeatStorePage'));
const TingoStorePage = lazy(() => import('./pages/TingoStorePage'));
const LocationPage = lazy(() => import('./pages/LocationPage'));
const NotFound = lazy(() => import('./modules/NotFound'));
const DiscountPage = lazy(() => import('./pages/DiscountPage'));
const BillPage = lazy(() => import('./pages/BillPage'));
const LoyaltyRedeemPage = lazy(() => import('./pages/LoyaltyRedeemPage'));
const ThankYouPage = lazy(() => import('./pages/ThankYouPage'));
const PurchasePage = lazy(() => import('./pages/PurchasePage'));
const SubscriptionPaymentPage = lazy(() =>
  import('./pages/SubscriptionPaymentPage')
);
const QMSPage = lazy(() => import('./pages/QMSPage'));
const ShortVideoPage = lazy(() => import('./pages/ShortVideoPage'));
const JukeboxPage = lazy(() => import('./pages/JukeboxPage'));
const SearchPage = lazy(() => import('./pages/SearchPage'));
const SocialRewardsRedeem = lazy(() => import('./pages/SocialRewardsRedeem'));
const ShareableVouchersPage = lazy(() =>
  import('./pages/ProfilePage/pages/ShareableVouchersPage')
);
const RateMyOrderPage = lazy(() => import('./pages/RateMyOrderPage'));
const OAuthPage = lazy(() => import('./pages/OAuthPage'));
const OrderTrackingPage = lazy(() => import('./pages/OrderTrackingPage'));

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const ratingsPage = useSelector(
    (state) => state.source.ratingsPageVisibility
  );

  const snackMessage = useSelector((state) => state.misc.snackMessage);

  ReactGA.initialize(GA_KEY);

  useEffect(() => {
    /**GA Pageview*/
    ReactGA.pageview(history.location.pathname + history.location.search);
  }, [history.location.pathname, history.location.search]);

  useEffect(() => {
    window.onload = function () {
      const appVersion = window.localStorage.getItem('appVersion');
      if (!appVersion) {
        window.localStorage.setItem('appVersion', '22.09.27c');
      } else if (appVersion !== '22.09.27c') {
        toast.info('updating new version');
        setTimeout(() => {
          window.localStorage.clear();
          window.localStorage.setItem('appVersion', '22.09.27c');
          window.location.reload();
        }, 2000);
      }
    };
  }, []);

  useEffect(() => {
    if (ratingsPage) {
      if (history.location.pathname !== '/') {
        dispatch(push('/ratings'));
      }
    } else if (history.location.pathname === '/ratings') {
      dispatch(push('/'));
    }
  }, [ratingsPage, dispatch, history.location.pathname]);

  const theme = window.localStorage.theme ? window.localStorage.theme : 'light';
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <div className="pageStyle">
        <Ably />
        <UserGroupRestore />
        <Switch>
          <ScannedRouterHoc
            exact
            path="/social/enigma"
            component={suspenseHoc(footerHoc(EnigmaPage))}
          />
          <Route
            exact
            path="/social/jukebox"
            component={suspenseHoc(footerHoc(JukeboxPage))}
          />
          <Route
            exact
            path="/social/2048"
            component={suspenseHoc(footerHoc(Game2048))}
          />
          <ScannedRouterHoc
            path="/social"
            component={suspenseHoc(footerHoc(SocialPage))}
          />
          <ScannedRouterHoc
            path="/food-court"
            component={suspenseHoc(footerHoc(FoodCourtPage))}
          />
          <ScannedRouteHomeHoc
            path="/home"
            component={suspenseHoc(footerHoc(HomePage))}
          />
          <ScannedRouteHomeHoc
            path="/search"
            component={suspenseHoc(footerHoc(SearchPage))}
          />
          <ScannedRouteHomeHoc
            path="/cart"
            component={suspenseHoc(footerHoc(CartPage))}
          />
          <PaymentRouterHoc
            path="/payment"
            component={suspenseHoc(PaymentPage)}
          />
          <ScannedRouterHoc
            path="/coupon"
            component={suspenseHoc(ApplyCouponPage)}
          />
          <ScannedRouterHoc
            path="/redeem"
            component={suspenseHoc(LoyaltyRedeemPage)}
          />
          <PaymentRouterHoc
            exact
            path="/bill"
            component={suspenseHoc(BillPage)}
          />
          <PrivateRouteHoc
            path="/track"
            component={suspenseHoc(OrderTrackingPage)}
          />
          <PrivateRouteHoc
            exact
            path="/social-voucher-redeem"
            component={suspenseHoc(SocialRewardsRedeem)}
          />
          <PrivateRouteHoc
            exact
            path="/profile"
            component={suspenseHoc(ProfilePage)}
          />
          <PrivateRouteHoc
            exact
            path="/profile/myProfile"
            component={suspenseHoc(EditProfilePage)}
          />

          <PrivateRouteHoc
            exact
            path="/profile/transactions"
            component={suspenseHoc(TransactionsPage)}
          />
          <PrivateRouteHoc
            exact
            path="/profile/rewards"
            component={suspenseHoc(RewardsPage)}
          />
          <PrivateRouteHoc
            exact
            path="/profile/shareable-vouchers"
            component={suspenseHoc(ShareableVouchersPage)}
          />
          {/* <PrivateRouteHoc
            exact
            path="/profile/loyalty"
            component={suspenseHoc(LoyaltyPage)}
          /> */}
          <PrivateRouteHoc
            exact
            path="/ratings"
            component={suspenseHoc(RatingsPage)}
          />
          <PrivateRouteHoc
            exact
            path="/credits"
            component={suspenseHoc(RewardCreditsPage)}
          />
          <PrivateRouteHoc
            exact
            path="/rewards"
            component={suspenseHoc(RewardSelectionPage)}
          />
          <Route
            exact
            path="/discounts"
            component={suspenseHoc(DiscountPage)}
          />
          <Route
            exact
            path="/rate/:id"
            component={suspenseHoc(RateMyOrderPage)}
          />
          <Route
            exact
            path="/broeat/:id"
            component={suspenseHoc(BroeatStorePage)}
          />
          <Route
            exact
            path="/tingo/:id"
            component={suspenseHoc(TingoStorePage)}
          />
          <Route
            exact
            path="/pay"
            component={suspenseHoc(SubscriptionPaymentPage)}
          />
          <Route exact path="/location" component={suspenseHoc(LocationPage)} />
          <PrivateRouteHoc
            exact
            path="/e-bill"
            component={suspenseHoc(EbillPage)}
          />
          <PrivateRouteHoc
            exact
            path="/thanks"
            component={suspenseHoc(ThankYouPage)}
          />
          <Route exact path="/oauth/:id" component={suspenseHoc(OAuthPage)} />
          <Route path="/short-videos" component={suspenseHoc(ShortVideoPage)} />
          <PrivateRouteHoc exact path="/q" component={suspenseHoc(QMSPage)} />
          <Route exact path="/purchase" component={suspenseHoc(PurchasePage)} />
          <Route exact path="/login" component={suspenseHoc(LoginPage)} />
          <Route exact path="/" component={suspenseHoc(LandingPage)} />
          <Route exact path="/qr_scan" component={suspenseHoc(QRScanPage)} />
          <Route component={suspenseHoc(NotFound)} />
        </Switch>
        <LoadingModal />
        <EnigmaModal />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover={false}
        />
        {snackMessage && snackMessage.message && (
          <Snackbar
            open={snackMessage.message && snackMessage.message !== ''}
            autoHideDuration={snackMessage.duration}
            onClose={() => {
              if (snackMessage.duration && snackMessage.duration !== null) {
                dispatch(setSnackMessage());
              }
            }}
            anchorOrigin={{
              vertical: snackMessage.verticalPos,
              horizontal: snackMessage.horizontalPos,
            }}
          >
            <Alert
              onClose={() => dispatch(setSnackMessage())}
              severity={snackMessage.type}
            >
              {snackMessage.message}
            </Alert>
          </Snackbar>
        )}
      </div>
    </ThemeProvider>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default App;
