import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {updateCurrentOrder} from './actions/groupActions';
import {
  removeUserOrder,
  ablyAck,
  updateUserOrder,
  getOrderDetails,
} from './actions';
import {withRouter} from 'react-router';
import {toast} from 'react-toastify';

function Ably({history}) {
  const dispatch = useDispatch();
  const userID = useSelector(
    (state) =>
      state.auth.userData &&
      state.auth.userData.user &&
      state.auth.userData.user._id
  );
  const orderID = useSelector(
    (state) => state.group.order && state.group.order._id
  );
  const tcReqID = useSelector((state) => state.auth.truecallerReqID);
  /** connect to order channel when orderID updated */
  useEffect(() => {
    if (orderID && window.orderChannel) {
      const orderListener = (message) => {
        console.log('order----->', message.data);
        dispatch(ablyAck(message.data.ably_id));
        dispatch(updateCurrentOrder(message.data.data.order_id));
      };
      window.orderChannel.attach();
      window.orderChannel.once('attached', () => {
        console.log('%cably order channel connected', 'color: green;');
        window.orderChannel.subscribe('order', orderListener);
      });
    }
  }, [orderID, dispatch]);
  /** connect to user channel when userID updated */
  useEffect(() => {
    if (userID && window.userChannel) {
      const userListener = (message) => {
        console.log('user---->', message.data);
        dispatch(ablyAck(message.data.ably_id));
        if (
          message.data.data &&
          message.data.data.updatedFields &&
          message.data.data.updatedFields.current_order
        ) {
          dispatch(
            updateUserOrder(message.data.data.updatedFields.current_order)
          );
        }
        if (
          message.data.data &&
          message.data.data.removedFields &&
          message.data.data.removedFields.length > 0
        ) {
          dispatch(removeUserOrder());
        }
      };
      const ordersListener = (message) => {
        console.log('userOrders---->', message.data);
        dispatch(ablyAck(message.data.ably_id));
        if (message.data.data && message.data.data.order_id) {
          dispatch(getOrderDetails(message.data.data.order_id));
        }
      };
      window.userChannel.attach();
      window.userChannel.once('attached', () => {
        console.log('%cably user channel connected', 'color: green;');
        window.userChannel.subscribe('user', userListener);
        window.userChannel.subscribe('order', ordersListener);
      });
    }
  }, [userID, dispatch, history]);

  useEffect(() => {
    if (tcReqID && window.tcReqID) {
      const truecallerListener = (message) => {
        clearInterval(window.trueCallerInterval);
        dispatch(ablyAck(message.data.ably_id));
        if (message.data.data && message.data.data.success) {
          localStorage.setItem('auth_token', message.data.data.success.token);
          dispatch({
            type: 'LOGIN_SUCCESS',
            payload: message.data.data.success,
          });
        }
        if (message.data.data && message.data.data.error) {
          toast.error(message.data.data.error);
        }
      };
      window.tcReqID.attach();
      window.tcReqID.once('attached', () => {
        console.log('%cably truecaller channel connected', 'color: green;');
        window.tcReqID.subscribe('truecaller', truecallerListener);
      });
    }
    // eslint-disable-next-line
  }, [tcReqID]);
  return <></>;
}

export default withRouter(Ably);
