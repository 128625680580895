import * as actionTypes from '../../actionTypes';
import {API_URL} from '../../../utils/constants';
import {http} from '../../http';

export const getPurchaseOrder = (id) => (dispatch) => {
  dispatch({type: actionTypes.LOADING_ORDER, payload: true});
  http
    .get(`${API_URL}/purchase_order/${id}`)
    .then((res) => {
      dispatch({type: actionTypes.INIT_PURCHASE_ORDER, payload: res.data});
      dispatch({type: actionTypes.LOADING_ORDER, payload: false});
    })
    .catch(() => {
      dispatch({type: actionTypes.LOADING_ORDER, payload: false});
    });
};

export const updatePurchaseOrder = (id, data) => (dispatch) => {
  dispatch({type: actionTypes.LOADING_ORDER, payload: true});
  http
    .put(`${API_URL}/purchase_order/${id}`, data)
    .then((res) => {
      dispatch({type: actionTypes.INIT_PURCHASE_ORDER, payload: res.data});
      dispatch({type: actionTypes.LOADING_ORDER, payload: false});
    })
    .catch(() => {
      dispatch({type: actionTypes.LOADING_ORDER, payload: false});
    });
};
