import Axios from 'axios';
import {resetGroup, updateOrder} from '..';
import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';

export const addCartItem = (item) => {
  return (dispatch, getState) => {
    const orderType = getState().misc.orderMode;
    if (orderType !== 'dinein') {
      dispatch({
        type: actionTypes.RESET_GROUP,
        payload: item,
      });
    }
    dispatch({
      type: actionTypes.ADD_TO_CART,
      payload: item,
    });
    if (orderType === 'delivery' || orderType === 'takeaway') {
      dispatch(getQuote(orderType));
    }
  };
};

export const removeCartItem = (item) => {
  return (dispatch, getState) => {
    const orderType = getState().misc.orderMode;
    if (orderType !== 'dinein') {
      dispatch({
        type: actionTypes.RESET_GROUP,
        payload: item,
      });
    }
    dispatch({
      type: actionTypes.DELETE_FROM_CART,
      payload: item,
    });
    if (orderType === 'delivery' || orderType === 'takeaway') {
      dispatch(getQuote(orderType));
    }
  };
};

export const addonSelection = (item) => {
  return (dispatch, getState) => {
    const addonSelected = getState().cart.addonSelection;
    dispatch({
      type: actionTypes.ADDON_SELECTION,
      payload: item._id ? [...addonSelected, item] : [],
    });
  };
};

export const removeAddonSelection = () => {
  return (dispatch, getState) => {
    let addonSelected = getState().cart.addonSelection;
    addonSelected.splice(0, 1);
    dispatch({
      type: actionTypes.ADDON_SELECTION,
      payload: addonSelected,
    });
  };
};

export const setImageSelection = (item) => ({
  type: actionTypes.IMAGE_ITEM_SELECTION,
  payload: item,
});

export const addCartItemNote = (item, note) => ({
  type: actionTypes.ADD_TO_CART_NOTE,
  payload: {item: item, note: note},
});

export const removeCartItemNote = (item) => ({
  type: actionTypes.REMOVE_TO_CART_NOTE,
  payload: item,
});

export const addCartItemAddon = (item) => {
  return (dispatch, getState) => {
    const orderType = getState().misc.orderMode;
    if (orderType !== 'dinein') {
      dispatch({
        type: actionTypes.RESET_GROUP,
        payload: item,
      });
    }
    dispatch({
      type: actionTypes.ADD_TO_CART_ADDON,
      payload: item,
    });
    if (orderType === 'delivery' || orderType === 'takeaway') {
      dispatch(getQuote(orderType));
    }
  };
};

export const removeCartItemAddon = (item) => {
  return (dispatch, getState) => {
    const orderType = getState().misc.orderMode;
    if (orderType !== 'dinein') {
      dispatch({
        type: actionTypes.RESET_GROUP,
        payload: item,
      });
    }
    dispatch({
      type: actionTypes.DELETE_FROM_CART_ADDON,
      payload: item,
    });
    if (orderType === 'delivery' || orderType === 'takeaway') {
      dispatch(getQuote(orderType));
    }
  };
};

export const resetCart = () => ({
  type: actionTypes.CLEAR_CART,
});

export const resetLoyaltyCart = () => ({
  type: actionTypes.CLEAR_LOYALTY_CART,
});

export const addToLoyaltyCart = (data) => ({
  type: actionTypes.ADD_LOYALTY_CART,
  payload: data,
});

export const removeFromLoyaltyCart = (data) => ({
  type: actionTypes.REMOVE_LOYALTY_CART,
  payload: data,
});

export const getQuote = (type) => {
  return (dispatch, getState) => {
    const localCart = getState().cart.localCart;
    if (localCart.length > 0) {
      const store = getState().source.restData;
      const data = {
        items: [],
        platform: 'bolt',
        __type: type,
        store_id: store._id,
        address: store?.address,
      };
      data['address']['city'] = store?.address?.city?.name;
      localCart.forEach((item) => {
        const temp = {};
        temp['item_id'] = item._id;
        temp['addons'] = [];
        temp['qty'] = item.qty;
        temp['note'] = item.note;
        if (item.addons && item.addons.length > 0) {
          const tempAddon = [];
          item.addons.forEach((addon) => {
            if (addon.addon_items && addon.addon_items.length > 0) {
              const add = {
                _id: addon._id,
                addon_items: [],
              };
              addon.addon_items.forEach((addonItem) => {
                add['addon_items'].push({_id: addonItem._id});
              });
              tempAddon.push(add);
            }
          });
          temp['addons'] = tempAddon;
        }
        data['items'].push(temp);
      });
      Axios.post(`${API_URL}/orders/quote`, data)
        .then(async (res) => {
          await dispatch(updateOrder(res.data));
        })
        .catch(() => {});
    } else {
      dispatch(resetGroup());
    }
  };
};
