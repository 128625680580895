import * as actionTypes from '../../actionTypes';
import _ from 'lodash';

const initialState = {
  localCart: [],
  addonSelection: [],
  loyaltyCart: [],
  imageItemSection: {},
};

export default function miscReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_TO_CART: {
      const add = [...state.localCart];
      const addIndex = add.findIndex((a) => a._id === action.payload._id);
      if (addIndex > -1) {
        add[addIndex]['qty'] += 1;
        add[addIndex]['total'] =
          add[addIndex]['base_price'] * add[addIndex]['qty'];
      } else {
        let tempAdd = {...action.payload};
        tempAdd['qty'] = 1;
        tempAdd['note'] = '';
        tempAdd['total'] = action.payload.base_price;
        add.push(tempAdd);
      }
      return {
        ...state,
        localCart: add,
      };
    }
    case actionTypes.DELETE_FROM_CART: {
      const remove = [...state.localCart];
      const removeIndex = remove.findIndex((a) => a._id === action.payload._id);
      if (removeIndex > -1) {
        if (remove[removeIndex]['qty'] === 1) {
          remove.splice(removeIndex, 1);
        } else {
          remove[removeIndex]['qty'] -= 1;
          remove[removeIndex]['total'] =
            remove[removeIndex]['base_price'] * remove[removeIndex]['qty'];
        }
      }
      return {
        ...state,
        localCart: remove,
      };
    }
    case actionTypes.ADDON_SELECTION: {
      return {
        ...state,
        addonSelection: action.payload,
      };
    }
    case actionTypes.IMAGE_ITEM_SELECTION: {
      return {
        ...state,
        imageItemSection: action.payload,
      };
    }
    case actionTypes.ADD_TO_CART_ADDON: {
      const add = [...state.localCart];
      const addItemIndex = add.findIndex(
        (val) =>
          val._id === action.payload._id &&
          _.isEqual(val.addons, action.payload.addons)
      );

      const getAddonPrice = (item) => {
        if (item.addons.length === 0) {
          return item.base_price;
        } else {
          let temp = item.base_price;
          item.addons.forEach((obj) => {
            obj.addon_items.forEach((addon) => {
              temp = temp + addon.price;
            });
          });
          return temp;
        }
      };

      if (addItemIndex > -1) {
        add[addItemIndex]['qty'] += 1;
        add[addItemIndex]['total'] =
          getAddonPrice(action.payload) * add[addItemIndex]['qty'];
      } else {
        const tempAdd = {...action.payload};
        tempAdd['qty'] = 1;
        tempAdd['note'] = '';
        tempAdd['total'] = getAddonPrice(action.payload);
        add.push(tempAdd);
      }
      return {
        ...state,
        localCart: add,
      };
    }
    case actionTypes.DELETE_FROM_CART_ADDON: {
      const remove = [...state.localCart];
      const removeItemIndex = remove.findIndex(
        (val) =>
          val._id === action.payload._id &&
          _.isEqual(val.addons, action.payload.addons)
      );

      const getAddonPrice = (item) => {
        if (item.addons.length === 0) {
          return item.base_price;
        } else {
          let temp = item.base_price;
          item.addons.forEach((obj) => {
            obj.addon_items.forEach((addon) => {
              temp = temp + addon.price;
            });
          });
          return temp;
        }
      };
      if (removeItemIndex > -1) {
        if (remove[removeItemIndex]['qty'] === 1) {
          remove.splice(removeItemIndex, 1);
        } else {
          remove[removeItemIndex]['qty'] -= 1;
          remove[removeItemIndex]['total'] =
            getAddonPrice(action.payload) * remove[removeItemIndex]['qty'];
        }
      }
      return {
        ...state,
        localCart: remove,
      };
    }
    case actionTypes.ADD_TO_CART_NOTE: {
      const addNote = [...state.localCart];
      let addNoteIndex = -1;
      if (
        action.payload.item &&
        action.payload.item.addons &&
        action.payload.item.addons.length > 0
      ) {
        addNoteIndex = addNote.findIndex(
          (val) =>
            val._id === action.payload.item._id &&
            _.isEqual(val.addons, action.payload.item.addons)
        );
      } else {
        addNoteIndex = addNote.findIndex(
          (val) => val._id === action.payload.item._id
        );
      }
      if (addNoteIndex > -1) {
        addNote[addNoteIndex].note = action.payload.note;
      }
      return {
        ...state,
        localCart: addNote,
      };
    }
    case actionTypes.REMOVE_TO_CART_NOTE: {
      const removeNote = [...state.localCart];
      let removeNoteIndex = -1;
      if (
        action.payload.item &&
        action.payload.item.addons &&
        action.payload.item.addons.length > 0
      ) {
        removeNoteIndex = removeNote.findIndex(
          (val) =>
            val._id === action.payload.item._id &&
            _.isEqual(val.addons, action.payload.item.addons)
        );
      } else {
        removeNoteIndex = removeNote.findIndex(
          (val) => val._id === action.payload.item._id
        );
      }
      if (removeNote > -1) {
        removeNote[removeNoteIndex].note = '';
      }
      return {
        ...state,
        localCart: removeNote,
      };
    }
    case actionTypes.CLEAR_CART: {
      return {
        ...state,
        localCart: [],
      };
    }
    case actionTypes.ADD_LOYALTY_CART: {
      let addLoyaltyCart = [...state.loyaltyCart];
      let addLoyaltyIndex = addLoyaltyCart.findIndex(
        (a) => a._id === action.payload._id
      );
      if (addLoyaltyIndex > -1) {
        addLoyaltyCart[addLoyaltyIndex]['qty'] += 1;
        addLoyaltyCart[addLoyaltyIndex]['credits'] += action.payload.credits;
      } else {
        let tempAdd = {...action.payload};
        tempAdd['qty'] = 1;
        tempAdd['credits'] = action.payload.credits;
        addLoyaltyCart.push(tempAdd);
      }
      return {
        ...state,
        loyaltyCart: addLoyaltyCart,
      };
    }
    case actionTypes.REMOVE_LOYALTY_CART: {
      let removeLoyaltyCart = [...state.loyaltyCart];
      let removeLoyaltyIndex = removeLoyaltyCart.findIndex(
        (a) => a._id === action.payload._id
      );
      if (removeLoyaltyIndex > -1) {
        if (removeLoyaltyCart[removeLoyaltyIndex]['qty'] === 1) {
          removeLoyaltyCart.splice(removeLoyaltyIndex, 1);
        } else {
          removeLoyaltyCart[removeLoyaltyIndex]['qty'] -= 1;
          removeLoyaltyCart[removeLoyaltyIndex]['credits'] -=
            action.payload.credits;
        }
      }
      return {
        ...state,
        loyaltyCart: removeLoyaltyCart,
      };
    }
    case actionTypes.CLEAR_LOYALTY_CART: {
      return {
        ...state,
        loyaltyCart: [],
      };
    }
    default:
      return state;
  }
}
