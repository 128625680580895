import React from 'react';
import {useStyles} from './styles';
import {useTranslation} from 'react-i18next';
import {BillIcon} from '../../utils/icons';
import {withRouter} from 'react-router';
// import BillDrawer from './components/BillDrawer';
import {useSelector} from 'react-redux';
// import {setServiceModal} from '../../services/actions/miscActions';
import ServiceModal from '../ServiceModal';
import HomeIcon from './svgs/HomeIcon';
import InActiveHomeIcon from './svgs/InActiveHomeIcon';
import ActiveSocialIcon from './svgs/ActiveSocialIcon';
import InActiveSocialIcon from './svgs/InActiveSocialIcon';

function Footer({history}) {
  const classes = useStyles();
  const [t] = useTranslation();
  // const dispatch = useDispatch();
  const orderMode = useSelector((state) => state.misc.orderMode);
  const billMarked = useSelector(
    (state) =>
      state.group.order &&
      state.group.order.bill &&
      state.group.order.bill.kots &&
      state.group.order.bill.kots.length
  );
  const billStatus = useSelector(
    (state) => state.group.order && state.group.order._id
  );
  const fcBill = useSelector(
    (state) =>
      state.group.order &&
      state.group.order.__type === 'foodcourt' &&
      state.group.order._id
  );
  // const services = useSelector(
  //   (state) =>
  //     state.source.restData &&
  //     state.source.restData.preferences &&
  //     state.source.restData.preferences.services
  // );

  return (
    (orderMode === 'dinein' || orderMode === 'menu') && (
      <div
        className={classes.root}
        style={{
          justifyContent:
            orderMode === 'dinein' ? 'space-between' : 'space-around',
        }}
      >
        <div
          style={{paddingLeft: 28}}
          className={`${
            history.location.pathname.includes('/home') && classes.activeButton
          } ${classes.customButton}`}
          onClick={() => history.push('/home')}
        >
          <div>
            {history.location.pathname.includes('/home') ? (
              <HomeIcon />
            ) : (
              <InActiveHomeIcon />
            )}
          </div>
          <div>{t('Home')}</div>
        </div>
        {/* <div
          className={`${classes.customButton} ${
            !(
              (orderMode === 'dinein' || orderMode === 'hotel') &&
              services &&
              services.length > 0 &&
              services.filter((a) => a.active).length > 0
            ) && classes.disabled
          }`}
          onClick={() => {
            if (
              (orderMode === 'dinein' || orderMode === 'hotel') &&
              services &&
              services.length > 0 &&
              services.filter((a) => a.active).length > 0
            ) {
              dispatch(setServiceModal(true));
            }
          }}
        >
          <div>
            <ServiceIcon />
          </div>
          <div>{t('service')}</div>
        </div> */}
        {orderMode === 'dinein' && (
          <div
            className={`${classes.customButton} ${
              !(fcBill || billStatus) && classes.disabled
            }`}
            onClick={() => {
              if (
                (fcBill && fcBill !== '') ||
                (billStatus && billStatus !== '')
              ) {
                history.push(`/bill`);
              }
            }}
          >
            <div style={{position: 'relative'}}>
              <BillIcon />
              {billMarked && billMarked >= 1 && (
                <div className={classes.billMarked} />
              )}
            </div>
            <div>{t('bill')}</div>
          </div>
        )}
        {/* <BillDrawer /> */}
        <div
          style={{paddingRight: 28}}
          className={`${
            history.location.pathname.includes('/social') &&
            classes.activeButton
          } ${classes.customButton} ${
            !(orderMode === 'dinein' || orderMode === 'menu') &&
            classes.disabled
          }`}
          onClick={() => {
            if (orderMode === 'dinein' || orderMode === 'menu') {
              history.push('/social');
            }
          }}
        >
          <div>
            {history.location.pathname.includes('/social') ? (
              <ActiveSocialIcon />
            ) : (
              <InActiveSocialIcon />
            )}
          </div>
          <div>{t('enigma')}</div>
        </div>
        <ServiceModal />
      </div>
    )
  );
}

export default withRouter(Footer);
