import * as actions from '../../actionTypes';
import {API_URL} from '../../../utils/constants';
import {http} from '../../http';
import Axios from 'axios';
import {replace} from 'connected-react-router';

export const getUserRewards = () => async (dispatch) => {
  dispatch({type: actions.GET_USER_REWARDS_PENDING});
  try {
    const res = await http.get(`${API_URL}/rewards/user?limit=10&page=1`);
    dispatch({type: actions.GET_USER_REWARDS_SUCCESS, payload: res.data});
  } catch (err) {
    dispatch({type: actions.GET_USER_REWARDS_FAILED, payload: err});
  }
};

export const getUserRewardsPagination = (url) => {
  return (dispatch) => {
    http
      .get(url)
      .then((res) =>
        dispatch({
          type: actions.GET_USER_REWARDS_SUCCESS_PAGINATION,
          payload: res.data,
        })
      )
      .catch((err) => console.log(err));
  };
};

export const getRewards = (order_id) => async (dispatch) => {
  return new Promise(async function (resolve, reject) {
    dispatch({type: actions.GET_REWARDS_PENDING});
    try {
      var res = await http.get(`${API_URL}/rewards/user/order/${order_id}`);
      dispatch({type: actions.GET_REWARDS_SUCCESS, payload: res.data});
    } catch (err) {
      dispatch({type: actions.GET_REWARDS_FAILED, payload: err});
      reject(err);
    }
  });
};

export const getDiscountStore = (storeID) => async (dispatch) => {
  return new Promise(async function (resolve, reject) {
    dispatch({type: actions.LOADING_ORDER, payload: true});
    try {
      var res = await Axios.get(`${API_URL}/stores/${storeID}`);
      dispatch({type: actions.GET_DISCOUNT_STORE, payload: res.data});
      dispatch({type: actions.LOADING_ORDER, payload: false});
    } catch (err) {
      dispatch({type: actions.LOADING_ORDER, payload: false});
      dispatch({type: actions.DISCOUNT_STORE_ERROR});
      reject(err);
    }
  });
};

export const getPlatformVoucher = (voucherID) => async (dispatch) => {
  dispatch({type: actions.LOADING_ORDER, payload: true});
  try {
    var res = await Axios.get(`${API_URL}/vouchers/${voucherID}`);
    dispatch({type: actions.GET_DISCOUNT_VOUCHER, payload: res.data});
    dispatch({type: actions.LOADING_ORDER, payload: false});
  } catch (err) {
    dispatch({type: actions.LOADING_ORDER, payload: false});
    dispatch({type: actions.DISCOUNT_VOUCHER_ERROR});
  }
};

export const getVoucherBanners = (storeID) => async (dispatch) => {
  dispatch({type: actions.LOADING_ORDER, payload: true});
  try {
    var res = await Axios.get(`${API_URL}/banner/${storeID}?__type=VOUCHER`);
    dispatch({type: actions.GET_BANNER_VOUCHER, payload: res.data});
    dispatch({type: actions.LOADING_ORDER, payload: false});
  } catch (err) {
    dispatch({type: actions.LOADING_ORDER, payload: false});
  }
};

export const uploadScreenShot =
  (storeID, voucherID, phone, file) => async (dispatch) => {
    dispatch({type: actions.LOADING_ORDER, payload: true});
    const data = new FormData();
    data.append('file', file);
    data.append('upi_phone', phone);
    try {
      var res = await Axios.post(
        `${API_URL}/cashback/${storeID}/${voucherID}`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (res.data) {
        dispatch({type: actions.CASHBACK_APPLIED, payload: true});
        dispatch({type: actions.LOADING_ORDER, payload: false});
      }
    } catch (err) {
      dispatch({type: actions.LOADING_ORDER, payload: false});
    }
  };

export const getStoreCRM = (storeID) => async (dispatch) => {
  return new Promise(async function (resolve, reject) {
    dispatch({type: actions.LOADING_ORDER, payload: true});
    try {
      var res = await http.get(`${API_URL}/crm/user/${storeID}`);
      dispatch({type: actions.GET_CRM_DETAILS, payload: res.data});
      dispatch({type: actions.LOADING_ORDER, payload: false});
    } catch (err) {
      dispatch({type: actions.LOADING_ORDER, payload: false});
      reject(err);
    }
  });
};

export const updateStoreReview =
  (storeID, data, successCB) => async (dispatch) => {
    return new Promise(async function (resolve, reject) {
      dispatch({type: actions.LOADING_ORDER, payload: true});
      try {
        var res = await http.post(`${API_URL}/crm/${storeID}/review`, data);
        dispatch({type: actions.GET_CRM_DETAILS, payload: res.data});
        successCB();
        dispatch({type: actions.LOADING_ORDER, payload: false});
      } catch (err) {
        dispatch({type: actions.LOADING_ORDER, payload: false});
        reject(err);
      }
    });
  };

export const addSocialVouchers = (voucher_id) => {
  return (dispatch) => {
    dispatch({type: actions.LOADING_ORDER, payload: true});
    http
      .post(`${API_URL}/vouchers/${voucher_id}`, {})
      .then(() => {
        dispatch({type: actions.LOADING_ORDER, payload: false});
        dispatch(replace('/profile/rewards'));
      })
      .catch(() => {
        dispatch({type: actions.LOADING_ORDER, payload: false});
        dispatch(replace('/profile/rewards'));
      });
  };
};
