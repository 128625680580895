import React from 'react';
import {useSelector} from 'react-redux';
import Footer from '../../modules/Footer';

const footerHoc = (WrapComponent) => {
  function HocContent() {
    const storeID = useSelector(
      (state) => state.misc.scanData && state.misc.scanData.store_id
    );
    return (
      <>
        <WrapComponent />
        {Boolean(storeID) && <Footer />}
      </>
    );
  }
  return HocContent;
};

export default footerHoc;
